/* Fonts classes
========================================================================== */

@mixin base-types-color() {
  // as not defined for some font styles
  color: white;
}

.h1 {
  &-regular {
    @include h1-regular;
  }
  &-bold {
    @include h1-bold;
  }
  &-mono-bold {
    @include h1-mono-bold;
  }
}

.h2 {
  &-regular {
    @include h2-regular;
  }
  &-bold {
    @include h2-bold;
  }
  &-mono-bold {
    @include h2-mono-bold;
  }
}

.h3 {
  &-regular {
    @include h3-regular;
  }
  &-bold {
    @include h3-bold;
  }
  &-mono-bold {
    @include h3-mono-bold;
  }
}

.h4 {
  &-regular {
    @include h4-regular;
  }
  &-bold {
    @include h4-bold;
  }
  &-mono-bold {
    @include h4-mono-bold;
  }
}

.h5 {
  &-regular {
    @include h5-regular;
  }
  &-bold {
    @include h5-bold;
  }
  &-mono-bold {
    @include h5-mono-bold;
  }
}

.h6 {
  &-regular {
    @include h6-regular;
  }
  &-bold {
    @include h6-bold;
  }
  &-mono-bold {
    @include h6-mono-bold;
  }
}

.b1 {
  &-regular {
    @include b1-regular;
    @include base-types-color;
  }
  &-bold {
    @include b1-bold;
    @include base-types-color;
  }
  &-mono-bold {
    @include b1-mono-bold;
    @include base-types-color;
  }
}
.b2 {
  &-regular {
    @include b2-regular;
    @include base-types-color;
  }
  &-bold {
    @include b2-bold;
    @include base-types-color;
  }
  &-mono-bold {
    @include b2-mono-bold;
    @include base-types-color;
  }
}

.s1 {
  &-bold {
    @include s1-bold;
    @include base-types-color;
  }
  &-mono-bold {
    @include s1-mono-bold;
    @include base-types-color;
  }
}

.s2 {
  &-bold {
    @include s2-bold;
    @include base-types-color;
  }
  &-mono-bold {
    @include s2-mono-bold;
    @include base-types-color;
  }
}

.button {
  &-1 {
    &-bold {
      @include button-1-bold;
      @include base-types-color;
    }
    &-price-medium {
      @include button-1-price-medium;
      @include base-types-color;
    }
  }
  &-2 {
    &-bold {
      @include button-2-bold;
      @include base-types-color;
    }
    &-price-medium {
      @include button-2-price-medium;
      @include base-types-color;
    }
  }
}

.caption {
  &-regular {
    @include regular;
    @include base-types-color;
  }
  &-bold {
    @include regular;
    @include bold;
    @include base-types-color;
  }
}

.overline-medium {
  @include medium;
}

/* deprecated use */
.f {
  &-standard {
    font-family: $fontBase;
  }

  &-mono {
    font-family: $fontBase-mono;
    &.fw-bold {
      @extend .fw-500;
    }
    &.fw-bolder {
      @extend .fw-700;
    }
  }

  @each $size in [8, 10, 11, 12, 13, 14, 16, 20, 24, 28, 36, 48] {
    &-size-#{$size} {
      font-size: remy($size);
    }
  }
}

.fw {
  &-400 {
    font-weight: 400 !important;
  }
  &-500 {
    font-weight: 500 !important;
  }
  &-700 {
    font-weight: 700 !important;
  }
}
