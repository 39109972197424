.prices {
  &--combine {
    display: flex;
    align-items: flex-end;

    .btn {
      &-lg {
        min-width: remy(100);
      }

      &-sm {
        min-width: remy(70);
      }
    }

    .game-price {
      &:first-child {
        .btn {
          &--dark {
            background: $grey-medium-alpha-8;
          }

          &-lg,
          &-sm {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
      }

      &:last-child {
        .btn {
          &-lg,
          &-sm {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }
      }
    }
  }
}
