@import '../../ultraos-graphic-kit/assets/scss/ultra-tools';

.game-tooltip {
  &.tooltip .tooltip-inner {
    font-size: 12px;
    background-color: $grey-dark-4;
    border-radius: 4px;
    padding: 10px 15px;
  }

  &.bs-tooltip-top {
    padding: 0 0 7px;

    .tooltip-arrow {
      bottom: 2px;
    }
  }

  &.tooltip .tooltip-arrow:before {
    transform: rotate(180deg);
  }

  &.tooltip .tooltip-arrow:after {
    content: none;
  }
}

.token-tooltip {
  &.tooltip .tooltip-inner {
    font-size: 12px;
    background-color: $grey-dark-4;
    opacity: 0.9;
    border-radius: 4px;
    padding: 10px 15px;
  }

  &.bs-tooltip-top {
    padding: 0 0 7px;

    .tooltip-arrow {
      display: none;
    }
  }
}

.developer-name-tooltip {
  &.tooltip .tooltip-inner {
    font-size: 12px;
    background-color: $grey-dark-4;
    border-radius: 4px;
    text-transform: capitalize;
    opacity: 0.9;
    padding: 5px 10px;
    max-width: 250px;
  }
}
.grey-tooltip {
  &.tooltip .tooltip-inner {
    @include regular;
    color: $white-alpha-7;
    border-radius: 4px;
    background-color: $grey-light-2;
    max-width: 250px;
    border-color: $grey-light-3 !important;
  }

  .tooltip-arrow::before {
    transform: rotate(180deg);
    border-bottom: 4px solid $grey-light-2 !important;
  }

  &.tooltip .tooltip-arrow:after {
    content: none;
  }
}

@mixin generateTooltipStyles($className, $color, $background) {
  #{$className} {
    &.tooltip .tooltip-inner {
      @include regular;
      color: $color;
      border-radius: 4px;
      background: $background;
      max-width: 120px;
      border-color: $white-20;
      font-size: $b2-regular-font-size;
      text-align: center;
    }
  }
}
@include generateTooltipStyles('.transparent-purple-tooltip', $ultra-purple-light, $grey-4);
@include generateTooltipStyles('.transparent-orange-tooltip', $orange-light, $orange-light-transparent-hex);
@include generateTooltipStyles('.transparent-red-tooltip', $red-light, $red-light-transparent-hex);

.uniq-tooltip {
  &.tooltip .tooltip-inner {
    padding: 12px;
    min-height: 40px;
  }

  .tooltip-arrow::before {
    left: 2.5px;
  }
}

.sm-tooltip {
  &.tooltip .tooltip-inner {
    font-size: remy(8);
    opacity: 0.9;
  }
}

.md-tooltip {
  &.tooltip .tooltip-inner {
    font-size: remy(10);
    opacity: 0.9;
  }
}

.tooltip-no-arrow {
  &.tooltip .tooltip-arrow {
    display: none !important;
  }
}

.tooltip-text-uppercase {
  &.tooltip .tooltip-inner {
    text-transform: uppercase;
  }
}

.tooltip-text-capitalize {
  &.tooltip .tooltip-inner {
    text-transform: capitalize;
  }
}
