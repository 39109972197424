/* Cartridge
========================================================================== */

.cartridge {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  border-radius: 4px;
  @include medium;
  color: $grey-dark-2-standard-bg;
  padding: 4px 8px;
  position: absolute;
  top: 0px;
  left: -8px;
  box-shadow: 0 1px 10px $black-alpha-2, 0 4px 5px $black-alpha-12, 0 2px 4px $black-alpha-14;
  transition: all 0.3s ease;
  z-index: 1;
}
