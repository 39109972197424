.tooltip {
  // temporary fix for tooltip in grid card extended view
  &.z-infinite {
    z-index: 99999;
  }

  &.show {
    opacity: 1;
  }

  &.bottom-right {
    .tooltip-arrow {
      left: auto !important;
      right: 4px;
    }
  }

  .tooltip-inner {
    @include b2-regular;
    padding: remy(10);
    color: $white-alpha-7;
    background-color: $grey-dark-4;
    border: 1px solid $white-alpha-1;
    max-width: 300px;
    text-align: left;
  }

  .tooltip-arrow {
    // temporary removed from tooltip, we can improve it in https://ultraio.atlassian.net/browse/GP-18453
    display: none;

    &:before {
      z-index: 10;
      bottom: -1px;
      border: 4px solid transparent;
      border-bottom: 4px solid $grey-dark-4;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: -1px;
      border: 5px solid transparent;
      border-bottom: 5px solid $white-alpha-1;
      right: 4px;
    }
  }

  &.tooltip--hidden {
    display: none;
  }

  &.tooltip--success {
    .tooltip-inner {
      color: $green-light;
    }
  }

  &.tooltip--warning {
    .tooltip-inner {
      color: $orange-light;
    }
  }

  &.tooltip--error {
    .tooltip-inner {
      color: $red-light;
    }
  }

  &.tooltip--primary {
    .tooltip-inner {
      color: $ultra-purple-light;
    }
  }

  &.tooltip--neutral {
    .tooltip-inner {
      color: $white-alpha-5;
    }
  }
}
