$_01-greys-ultra-grey-2: #28262c;
$_04-uniq-border-common: #706e75;
$_04-uniq-border-epic: #8576a4;
$_04-uniq-border-legendary: #9f8a71;
$_04-uniq-border-legendary-transparent: #4c4445;
$_04-uniq-border-rare: #5c7da4;
$_04-uniq-border-uncommon: #68956f;
$_04-uniq-border-common-transparent: #504d55;
$_04-uniq-border-epic-transparent: #463f51;
$_04-uniq-border-rare-transparent: #3c4151;
$_04-uniq-border-uncommon-transparent: #3f4744;
$black-10: rgba(0, 0, 0, 0.1);
$black-100: #000000;
$black-12: rgba(0, 0, 0, 0.12);
$black-14: rgba(0, 0, 0, 0.14);
$black-20: rgba(0, 0, 0, 0.2);
$black-30: rgba(0, 0, 0, 0.3);
$black-50: rgba(0, 0, 0, 0.5);
$black-75: rgba(0, 0, 0, 0.75);
$blue-dark: #4396d6;
$blue-dark-active: #3c87c1;
$blue-dark-disabled: #224b6b;
$blue-dark-hover: #55a0da;
$blue-dark-transparent: rgba(67, 150, 214, 0.1);
$blue-dark-transparent-active: rgba(67, 150, 214, 0.3);
$blue-dark-transparent-disabled: rgba(67, 150, 214, 0.05);
$blue-dark-transparent-hover: rgba(67, 150, 214, 0.2);
$blue-light: #74c3ff;
$blue-light-active: #69b0e6;
$blue-light-disabled: #3a6280;
$blue-light-hover: #82c9ff;
$blue-light-transparent: rgba(116, 195, 255, 0.1);
$blue-light-transparent-active: rgba(116, 195, 255, 0.3);
$blue-light-transparent-disabled: rgba(116, 195, 255, 0.05);
$blue-light-transparent-hover: rgba(116, 195, 255, 0.2);
$cyan-dark: #30a6a6;
$cyan-dark-active: #2b9696;
$cyan-dark-disabled: #185353;
$cyan-dark-hover: #44afaf;
$cyan-dark-transparent: rgba(48, 166, 166, 0.1);
$cyan-dark-transparent-active: rgba(48, 166, 166, 0.3);
$cyan-dark-transparent-disabled: rgba(48, 166, 166, 0.05);
$cyan-dark-transparent-hover: rgba(48, 166, 166, 0.2);
$cyan-light: #81ecec;
$cyan-light-active: #74d5d5;
$cyan-light-disabled: #417676;
$cyan-light-hover: #8deeee;
$cyan-light-transparent: rgba(129, 236, 236, 0.1);
$cyan-light-transparent-active: rgba(129, 236, 236, 0.3);
$cyan-light-transparent-disabled: rgba(129, 236, 236, 0.05);
$cyan-light-transparent-hover: rgba(129, 236, 236, 0.2);
$green-dark: #57b761;
$green-dark-active: #4ea557;
$green-dark-disabled: #2c5c31;
$green-dark-hover: #67be70;
$green-dark-transparent: rgba(87, 183, 97, 0.1);
$green-dark-transparent-active: rgba(87, 183, 97, 0.3);
$green-dark-transparent-disabled: rgba(87, 183, 97, 0.05);
$green-dark-transparent-hover: rgba(87, 183, 97, 0.2);
$green-light: #8de796;
$green-light-active: #7fd087;
$green-light-disabled: #47744b;
$green-light-hover: #98e9a0;
$green-light-transparent: rgba(141, 231, 150, 0.1);
$green-light-transparent-hex: #45494f;
$green-light-transparent-active: rgba(141, 231, 150, 0.3);
$green-light-transparent-disabled: rgba(141, 231, 150, 0.05);
$green-light-transparent-hover: rgba(141, 231, 150, 0.2);
$ultra-green-light-7: #55715f;
$ultra-green-light-e: #b0e4b5;
$ultra-green-light-transparent-50: rgba(#72cf7b, 0.5);
$grey: #3c3846;
$grey-2: #84828b;
$grey-3: #504c59;
$grey-4: #403a4a;
$grey-active: #3a3643;
$grey-dark-1: #3c3846;
$grey-dark-2-standard-bg: #312d36;
$grey-dark-3: #28262c;
$grey-dark-4: #232026;
$grey-disabled: #2d2a33;
$grey-dividers: #45424a;
$grey-hover: #474351;
$grey-light-1: #464250;
$grey-light-2: #55525f;
$grey-light-3: #706d78;
$grey-light-4: #9c9aa1;
$grey-light-6: #d9d9d9;
$grey-transparent: rgba(60, 56, 70, 0.1);
$grey-transparent-active: rgba(60, 56, 70, 0.3);
$grey-transparent-disabled: rgba(60, 56, 70, 0.05);
$grey-transparent-hover: rgba(60, 56, 70, 0.2);
$indigo-dark: #908bd5;
$indigo-dark-active: #827dc0;
$indigo-dark-disabled: #48466b;
$indigo-dark-hover: #9b96d9;
$indigo-dark-transparent: rgba(144, 139, 213, 0.1);
$indigo-dark-transparent-active: rgba(144, 139, 213, 0.3);
$indigo-dark-transparent-disabled: rgba(144, 139, 213, 0.05);
$indigo-dark-transparent-hover: rgba(144, 139, 213, 0.2);
$indigo-light: #b3adff;
$indigo-light-active: #a19ce6;
$indigo-light-disabled: #5a5780;
$indigo-light-hover: #bab5ff;
$indigo-light-transparent: rgba(179, 173, 255, 0.1);
$indigo-light-transparent-active: rgba(179, 173, 255, 0.3);
$indigo-light-transparent-disabled: rgba(179, 173, 255, 0.05);
$indigo-light-transparent-hover: rgba(179, 173, 255, 0.2);
$orange-dark: #dc9532;
$orange-dark-active: #c6862d;
$orange-dark-disabled: #6e4b19;
$orange-dark-hover: #e0a046;
$orange-dark-transparent: rgba(220, 149, 50, 0.1);
$orange-dark-transparent-active: rgba(220, 149, 50, 0.3);
$orange-dark-transparent-disabled: rgba(220, 149, 50, 0.05);
$orange-dark-transparent-hover: rgba(220, 149, 50, 0.2);
$orange-light: #fad198;
$orange-light-active: #e1bd89;
$orange-light-disabled: #7d694c;
$orange-light-hover: #fad6a2;
$orange-light-alpha10-hex: #585058;
$orange-light-transparent: rgba(250, 209, 152, 0.1);
$orange-light-transparent-hex: #453d40;
$orange-light-transparent-active: rgba(250, 209, 152, 0.3);
$orange-light-transparent-disabled: rgba(250, 209, 152, 0.05);
$orange-light-transparent-hover: rgba(250, 209, 152, 0.2);
$pink-dark: #d175a1;
$pink-dark-active: #bd6a91;
$pink-dark-disabled: #693b51;
$pink-dark-hover: #d683aa;
$pink-dark-transparent: rgba(209, 117, 161, 0.1);
$pink-dark-transparent-active: rgba(209, 117, 161, 0.3);
$pink-dark-transparent-disabled: rgba(209, 117, 161, 0.05);
$pink-dark-transparent-hover: rgba(209, 117, 161, 0.2);
$pink-light: #f598c5;
$pink-light-active: #dd89b2;
$pink-light-disabled: #7b4c63;
$pink-light-hover: #f6a2cb;
$pink-light-transparent: rgba(245, 152, 197, 0.1);
$pink-light-transparent-active: rgba(245, 152, 197, 0.3);
$pink-light-transparent-disabled: rgba(245, 152, 197, 0.05);
$pink-light-transparent-hover: rgba(245, 152, 197, 0.2);
$primary-ultra-purple: #896ae2;
$primary-ultra-purple-light: #c5abff;
$primary-ultra-purple-light-alpha10-hex: #463f55;
$red-dark: #ea6161;
$red-dark-active: #d35757;
$red-dark-disabled: #753131;
$red-dark-hover: #ec7070;
$red-dark-transparent: rgba(234, 97, 97, 0.1);
$red-dark-transparent-active: rgba(234, 97, 97, 0.3);
$red-dark-transparent-disabled: rgba(234, 97, 97, 0.05);
$red-dark-transparent-hover: rgba(234, 97, 97, 0.2);
$red-light: #fb9393;
$red-light-active: #e28585;
$red-light-disabled: #7e4a4a;
$red-light-hover: #fb9e9e;
$red-light-transparent: rgba(251, 147, 147, 0.1);
$red-light-transparent-hex: #45373f;
$red-light-transparent-active: rgba(251, 147, 147, 0.3);
$red-light-transparent-disabled: rgba(251, 147, 147, 0.05);
$red-light-transparent-hover: rgba(251, 147, 147, 0.2);
$secondary-yellow-light: #faf6a7;
$ultra-purple: #896ae2;
$ultra-purple-active: #7c60cc;
$ultra-purple-disabled: #453571;
$ultra-purple-hover: #9579e5;
$ultra-purple-hover-2: #a78af1;
$ultra-purple-light-hover: #cbb3ff;
$ultra-purple-light-transparent-hover: rgba(197, 171, 255, 0.2);
$ultra-purple-light-active: #b69dea;
$ultra-purple-light-disabled: #5f5376;
$ultra-purple-light-transparent: rgba(197, 171, 255, 0.1);
$ultra-purple-light-transparent-active: rgba(197, 171, 255, 0.3);
$ultra-purple-light-transparent-disabled: rgba(197, 171, 255, 0.05);
$ultra-purple-transparent: rgba(137, 106, 226, 0.1);
$ultra-purple-transparent-active: rgba(129, 98, 213, 0.3);
$ultra-purple-transparent-disabled: rgba(137, 106, 226, 0.05);
$ultra-purple-transparent-hover: rgba(137, 106, 226, 0.2);
$white-5: rgba(255, 255, 255, 0.05);
$white-10: rgba(255, 255, 255, 0.1);
$white-10-hex: #46424a;
$white-100: #ffffff;
$white-20: rgba(255, 255, 255, 0.2);
$white-30: rgba(255, 255, 255, 0.3);
$white-50: rgba(255, 255, 255, 0.5);
$white-70: rgba(255, 255, 255, 0.7);
$yellow-dark: #eac534;
$yellow-dark-active: #d3b22f;
$yellow-dark-disabled: #75631a;
$yellow-dark-hover: #eccb48;
$yellow-dark-transparent: rgba(234, 197, 52, 0.1);
$yellow-dark-transparent-active: rgba(234, 197, 52, 0.3);
$yellow-dark-transparent-disabled: rgba(234, 197, 52, 0.05);
$yellow-dark-transparent-hover: rgba(234, 197, 52, 0.2);
$yellow-light-active: #e1dd96;
$yellow-light-disabled: #7b7354;
$yellow-light-hover: #fbf7b0;
$yellow-light-transparent: rgba(250, 246, 167, 0.1);
$yellow-light-transparent-active: rgba(250, 246, 167, 0.3);
$yellow-light-transparent-disabled: rgba(246, 230, 167, 0.05);
$yellow-light-transparent-hover: rgba(250, 246, 167, 0.2);
