@keyframes rotatePlane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  40% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  55% {
    transform: perspective(120px) rotateX(0deg) rotateY(179.9deg);
  }
  70% {
    transform: perspective(120px) rotateX(179.9deg) rotateY(180deg);
  }
  85% {
    transform: perspective(120px) rotateX(180deg) rotateY(0.1deg);
  }
}
