/*!
 * Bootstrap v4.1.1 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

@import 'bootstrap-color';
@import 'bootstrap-spacers';
@import 'bootstrap-gutters';
@import 'bootstrap-btn-variables';
@import 'bootstrap-modal-variables';

@import '../../../../../node_modules/bootstrap/scss/bootstrap';

@import 'custom/bootstrap-custom';
