@import '../../ultraos-graphic-kit/assets/scss/ultra-tools';

.btn {
  &-sm {
    font-size: remy(12);
    padding: remy(5) remy(8);
    letter-spacing: remy(0.25);
  }

  &-lg {
    font-size: remy(12);
    padding: remy(10) remy(12);
    letter-spacing: remy(0.75);
  }

  &-outline-dark {
    color: $white-alpha-7;
    border-color: $white-alpha-12;

    &:hover {
      border-color: $grey-light !important;
      background: $grey-light !important;
    }
  }

  &-dark {
    color: $white !important;
    border-color: $grey-medium !important;
    background: $grey-medium !important;

    &:not([disabled]):hover {
      border-color: $grey !important;
      background: $grey !important;
    }
  }

  &-access {
    color: $white;
    height: remy(32);
    text-align: center;
    padding: remy(6) remy(12);
  }

  &-primary {
    color: $white !important;

    &:not([disabled]):hover {
      background-color: $purple-dark;
      border-color: $purple-dark;
    }

    &[disabled] {
      background-color: $primary-disabled;
      border-color: $primary-disabled;
    }
  }

  &-warning {
    color: $dark-medium-emphasis;
  }

  &-close {
    background: transparent;

    .icon-bold-cross {
      margin-top: -5px;
    }
  }
}
