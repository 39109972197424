.p-100 {
  padding: 100px;
}
.ps-100 {
  padding-left: 100px;
}
.pe-100 {
  padding-right: 100px;
}
.pb-100 {
  padding-bottom: 100px;
}
.pt-100 {
  padding-top: 100px;
}
