/* Dropdown
========================================================================== */

.dropdown {
  &:before,
  &:after {
    content: '';
    position: absolute;
  }

  &:before {
    z-index: 5;
    width: 16px;
    right: 0.5rem;
    height: 16px;
    bottom: -13px;
    transform: rotate(45deg);
    border: 1px solid transparent;
  }

  &:after {
    height: 2px;
    bottom: -4px;
    width: 16px;
    right: 0.53rem;
    z-index: 1001;
  }

  &-centered .dropdown-menu {
    top: -50% !important;
  }

  &-right-top {
    &:before {
      right: -0.5rem;
    }

    &:after {
      right: -0.5rem;
      transform: rotate(90deg);
    }

    .dropdown-menu {
      right: 0;
      left: auto;
    }
  }

  &-without-icon {
    &:after {
      display: none;
    }
  }

  .dropdown-menu {
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2), 0 3px 14px rgba(0, 0, 0, 0.12), 0 8px 10px rgba(0, 0, 0, 0.14);
  }

  &-toggle {
    display: flex;
    align-items: center;
    font-size: remy(12);
    font-family: $fontBase;

    &:after {
      top: 50%;
      opacity: 1;
      right: remy(9);
      position: absolute;
      transform: translateY(-50%);
      transition: opacity $animation-time;
    }
  }

  &-item {
    cursor: pointer;
    font-size: remy(12);
    line-height: remy(22);
    font-family: $fontBase;
    letter-spacing: remy(0.25);
    padding: 0.35em remy(24) 0.35em remy(36);
    display: flex;
    align-items: center;
    position: relative;
    justify-content: flex-start;
    transition: color $animation-time, background-color $animation-time;

    .icon {
      top: 50%;
      left: remy(12.5);
      position: absolute;
      margin-right: 10px;
      transform: translateY(-50%);
      background-color: $white-alpha-7;
    }
  }

  &.show {
    .dropdown-toggle {
      &:after {
        opacity: 0;
      }
    }
  }

  &-dark {
    &.show {
      .dropdown-toggle {
        &:not(.btn-transparent):not(.bg-transparent) {
          border-color: $white;
          color: $white;
        }
      }

      i {
        background-color: $white !important;
      }

      &:before,
      &:after {
        border-color: $white-alpha-12;
      }
    }

    .dropdown {
      &-item {
        color: $white-alpha-7;

        &--no-hover:hover {
          cursor: default;
          background-color: transparent;
        }

        &:not(.dropdown-item--no-hover):hover,
        &:focus,
        &:active {
          color: $white;
          background-color: $grey-hover;
          border-radius: 4px;

          .icon:not(.bg-blue-middle-dark) {
            background-color: $white;
          }
        }
      }

      &-divider {
        border-color: $white-alpha-12;
      }

      &-menu {
        border-color: $white-alpha-12;
        background-color: $grey-dark-4;
      }
    }
  }

  &.dropdown-select {
    .dropdown {
      &-toggle {
        &:after {
          opacity: 0.5;
        }
      }
    }

    &:before,
    &:after {
      content: none;
    }
  }

  &-menu-arrow:after {
    content: '';
    position: absolute;
    top: -10px;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #252229 transparent;
  }

  &-menu-arrow-right:after {
    right: 10px;
  }

  &-menu-arrow-left:after {
    left: 10px;
  }
}

@media (max-width: 576px) {
  .dropdown {
    & .dropdown-menu {
      min-width: 100%;
    }
  }
}
