.table {
  background: transparent;
  border-collapse: separate;
  border-spacing: 0 15px;
  margin-top: -15px;

  thead {
    border-radius: 4px 4px 0 0;

    th {
      border: none;
      background-color: $grey-dark-dk;
      color: $white-alpha-7;
      font-size: 10px;
      font-family: $fontBase;
      line-height: 20px;
      text-transform: uppercase;
      letter-spacing: 1.5px;

      &:first-child {
        border-radius: 4px 0 0 0;
      }

      &:last-child {
        border-radius: 0 4px 0 0;
      }

      &:not(:last-child) {
        border-right: 1px solid $grey;
      }
    }
  }

  tbody {
    tr {
      background-color: transparent;
      border-radius: 4px;
      transition: background-color 0.3s ease;

      td {
        padding: 5px;
        border-color: transparent;

        &:first-child {
          border-radius: 4px 0 0 4px;
        }

        &:last-child {
          border-radius: 0 4px 4px 0;
        }
      }

      &:hover {
        td {
          background-color: $grey;
        }
      }
    }
  }
}
