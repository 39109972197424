@import '../../../assets/scss/non-compilable-tools';

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@mixin spinner-base {
  width: 1rem;
  height: 1rem;
  margin-left: 5px;
  animation: spinner 400ms linear infinite;
  border-bottom-color: transparent;
  border-right-color: transparent;
  border-style: solid;
  border-width: 2px;
  border-radius: 50%;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;

  @content;

  &-light {
    border-top-color: $white;
    border-left-color: $white;
  }

  &-slow {
    animation: spinner 1.5s linear infinite;
  }
}

.spinner {
  @include spinner-base {
    border-top-color: $ultra-purple-light;
    border-left-color: $ultra-purple-light;
  }
}
