.effect-level-up {
  top: 50%;
  z-index: 5;
  opacity: 0;
  left: 100%;
  position: absolute;
  transform: translateY(-50%);

  &--start {
    animation-play-state: running;
    animation: effectLevelUpAnimation 1.3s ease-in-out;
  }

  &:not(.effect-level-up--start) {
    top: 50%;
    opacity: 0;
    animation-play-state: paused;
  }

  &.notifying-tooltip {
    margin-left: 0.2rem;
    color: $white;
    white-space: nowrap;
    text-shadow: 0 0 3px black;
  }
}

@keyframes effectLevelUpAnimation {
  0% {
    top: 50%;
    opacity: 0;
    left: 95%;
  }
  20% {
    top: 50%;
    opacity: 1;
    left: 105%;
  }
  60% {
    top: 50%;
    left: 105%;
    opacity: 1;
  }
  100% {
    top: 30%;
    left: 105%;
    opacity: 0;
  }
}
