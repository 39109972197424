/* Cursors
========================================================================== */

$cursors: none, help, pointer, progress, wait, cell, text, alias, copy, move, not-allowed, zoom-in, zoom-out, grab,
  default;

@each $type in $cursors {
  .cursor-#{$type} {
    cursor: #{$type};
  }
}

.avoid-clicks {
  pointer-events: none;
}

.cursor-move {
  cursor: url('../../../../../icons/svg/hand-grab.svg'), auto !important;
}
