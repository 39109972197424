/* Border, border radius
========================================================================== */

$px-values: 4, 8, 20, 30, 34, 100;

@each $i in $px-values {
  .radius-#{$i} {
    border-radius: #{$i}px !important;
  }
}

.border {
  border: $border-width solid $border-default-color;
}

.radius-0 {
  border-radius: 0 !important;
}

.radius {
  border-radius: $border-radius; // 4px
}
