@mixin skeletonScreen() {
  overflow: hidden;

  &:empty::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-size: 600% 600%;
    background-image: linear-gradient(90deg, rgba(white, 0.15) 0, rgba(white, 0.05) 50%, rgba(white, 0.15) 100%);
    animation: skeletonScreenAnimation 1s linear infinite;
  }

  @keyframes skeletonScreenAnimation {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: -100% 0;
    }
  }
}
