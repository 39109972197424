@-webkit-keyframes autofill {
  to {
    color: #fff;
    background: transparent;
  }
}

@-webkit-keyframes autofill-dark {
  to {
    color: #fff;
    background: rgba(37, 34, 41, 0.6);
  }
}
