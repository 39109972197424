/* Z-index
========================================================================== */

$class-slug: z-index !default;

// Available from 0 to 10

@for $i from 0 through 10 {
  .#{$class-slug}-#{$i} {
    z-index: $i;
  }
}
