$ultra-null: rgba(0, 0, 0, 0);

// todo CHAPTER GP-20034
@mixin conditionalProperty($color-key, $state, $element, $property, $important: false) {
  $schemePropertyName: #{$state}#{if($element != '' and $element != null, -#{$element}, '')}-#{$property};
  $value: get_color_scheme($color-key, dark, $schemePropertyName);
  #{$property}: $value if($important, !important, null);
  @if $value {
    @content;
  }
}

.ultra-btn {
  @include button-1-bold;

  position: relative;
  display: inline-flex;

  align-items: center;
  justify-content: center;

  -webkit-user-select: none;
  user-select: none;

  border-radius: 0.25rem; // to 're scss'
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;

  border: 1px solid;

  height: 32px;

  &:disabled,
  &.disabled {
    opacity: 1;
    pointer-events: none;
    cursor: default;
  }
  &:not([disabled]):hover,
  &:not(.disabled):hover {
    pointer-events: all;
    cursor: pointer;
  }

  &.button-styled--fill {
    color: #fff;
  }
  &.button-styled--stroke {
    background-color: transparent;
  }
  &.button-styled--transparent {
    border-color: $ultra-null;
    &:disabled,
    &.disabled {
      opacity: 0.3;
    }
    &:not([disabled]):hover,
    &:not(.disabled):hover {
      border-color: $ultra-null;
    }
  }

  &.button-shaped--circle {
    min-width: auto;
    border-radius: 50%;
    padding: 0.2rem; // to do
  }

  .icon {
    min-height: 18px; // to do for small button
    min-width: 18px; // to do for small button
    display: flex;
  }

  &.button-size--small {
    height: 24px;
    padding: 5px;

    .icon {
      min-height: 12px; // to do for small button
      min-width: 12px; // to do for small button
    }
  }

  &.button-size--medium {
    height: 36px;
    @include b1-bold;
  }

  &.button-size--large {
    height: 48px;
    @include b1-bold;
  }

  &:focus {
    // to check outline: green solid 3px;
  }

  $styled_button: (fill, stroke, transparent);

  @each $color-key, $color-value in $colors_schemes {
    $root_color: get_color_scheme($color-key);

    &.button-color--#{'' + $color-key} {
      @each $style in $styled_button {
        &.button-styled--#{'' + $style} {
          @if $style == fill {
            background-color: $root_color;
            border-color: get_color_scheme($color-key);
            @include conditionalProperty($color-key, 'default', null, 'color', true);

            &:not([disabled]),
            &:not(.disabled) {
              &:hover {
                background-color: get_color_scheme($color-key, dark, hover);
                border-color: get_color_scheme($color-key, dark, hover);

                box-shadow:
                  0 4px 5px 0 rgba(0, 0, 0, 0.2),
                  0 3px 14px 0 rgba(0, 0, 0, 0.12),
                  0 8px 10px 0 rgba(0, 0, 0, 0.14);
              }

              &:active,
              &:active:hover {
                background-color: get_color_scheme($color-key, dark, active);
                border-color: get_color_scheme($color-key, dark, active);

                box-shadow:
                  0 4px 5px 0 rgba(0, 0, 0, 0.2),
                  0 3px 14px 0 rgba(0, 0, 0, 0.12),
                  0 8px 10px 0 rgba(0, 0, 0, 0.14);
              }
            }

            &:disabled,
            &.disabled {
              background-color: get_color_scheme($color-key, dark, disabled);
              border-color: $ultra-null;
            }
          }
          @if $style == stroke {
            $stroke_color: get_color_scheme($color-key, dark, hover);

            border-color: $root_color;
            color: $root_color;

            &:not([disabled]),
            &:not(.disabled) {
              .icon {
                background-color: $root_color;
              }

              &:hover {
                color: $stroke_color;
                border-color: $stroke_color;

                .icon {
                  background-color: $stroke_color;
                }
              }
            }

            &:disabled,
            &.disabled {
              // todo
            }
          }
          @if $style == transparent {
            $light_color: get_color_scheme($color-key, light);

            background-color: get_color_scheme($color-key, light, transparent);
            color: $light_color;
            @include conditionalProperty($color-key, 'default', null, 'min-width', true);
            @include conditionalProperty($color-key, 'default', null, 'border-color', true);

            &:not([disabled]),
            &:not(.disabled) {
              .icon {
                background-color: $light_color;
                @include conditionalProperty(
                  $color-key,
                  'default',
                  'icon',
                  'background-color',
                  true
                );
              }

              &:hover {
                // color: $light_color;
                background-color: get_color_scheme($color-key, light, transparent-hover);
                @include conditionalProperty($color-key, 'hover', null, 'background-color', true);
                @include conditionalProperty($color-key, 'hover', null, 'border-color', true);

                .icon {
                  background-color: $light_color;
                  @include conditionalProperty(
                    $color-key,
                    'hover',
                    'icon',
                    'background-color',
                    true
                  );
                }
              }

              &:focus {
                content: #{$color-key};
                @include conditionalProperty($color-key, 'focus', null, 'outline-color', true) {
                  outline-offset: 2px;
                  outline-width: 1px !important; // to overwrite root !important styles
                  outline-style: solid !important; // to overwrite root !important styles
                }
                @include conditionalProperty($color-key, 'focus', null, 'border-color', true);
                @include conditionalProperty($color-key, 'focus', null, 'background-color');
                .icon {
                  @include conditionalProperty($color-key, 'focus', 'icon', 'background-color');
                }
              }

              &.--active,
              &:active,
              &:active:hover {
                background-color: get_color_scheme($color-key, light, transparent-active);
                color: $light_color;
                @include conditionalProperty($color-key, 'active', null, 'background-color', true);
                @include conditionalProperty($color-key, 'active', null, 'border-color', true);
                .icon {
                  @include conditionalProperty(
                    $color-key,
                    'active',
                    'icon',
                    'background-color',
                    true
                  );
                }
              }
            }

            &:disabled,
            &.disabled {
              .icon {
                @include conditionalProperty(
                  $color-key,
                  'disabled',
                  'icon',
                  'background-color',
                  true
                );
              }
            }
          }
        }
      }
    }
  }
}
