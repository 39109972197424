* {
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  // uncomment if need arrows on top and bottom
  &::-webkit-scrollbar-button {
    //background-color: $black-alpha-14;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px $black-alpha-3;
  }

  &::-webkit-scrollbar-track-piece {
    background-color: $white-alpha-12;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $grey-dark-dk;
    transition: $animation-time background-color;

    &:hover {
      background-color: $grey-dark-2-standard-bg;
    }
  }
}
