$pagination-height: 30px;

ngb-pagination {
  display: flex;
  align-items: center;

  ul.pagination {
    margin: 0;

    > li.page-item {
      > a {
        display: block;
        background: transparent !important;
        border: 2px solid transparent !important;
        padding: 0 10px;
        height: $pagination-height;
        line-height: $pagination-height - 4;
        color: $white-alpha-7;
        transition:
          border-bottom-color 0.3s ease,
          color 0.3s ease;

        &[aria-label='Previous'] > span,
        &[aria-label='Next'] > span {
          content: '';
          background-color: $white-alpha-7;
          display: block;
          -webkit-mask: url('../icons/svg/chevron.svg') no-repeat 50%;
          mask-size: 7.2px 12.8px;
          min-width: 7.2px;
          min-height: 12.8px;
        }

        &[aria-label='Next'] > span {
          transform: rotate(180deg);
        }
      }

      &.active > a {
        border-bottom-color: $ultra-purple;
        color: $white;
      }

      &.disabled {
        a[aria-label='Previous'] > span,
        a[aria-label='Next'] > span {
          background-color: $text-muted;
          opacity: 0;
        }
      }
    }
  }
}
