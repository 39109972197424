@import '../../tools';

$ng-select-color: $white;
$ng-select-border: 1px solid $white-alpha-1;
$ng-select-padding: remy(5) remy(15);
$ng-select-line-height: remy(18);

.ng-select {
  color: $ng-select-color;
  font-size: remy(12);

  &.ng-invalid.ng-touched .ng-select-container {
    caret-color: $danger;
    border-color: $danger !important;
  }

  &.ng-select-opened {
    &.ng-select-bottom {
      > .ng-select-container {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
    &.ng-select-top {
      > .ng-select-container {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
      }
    }

    > .ng-select-container {
      .ng-arrow {
        top: -2px;
        border-color: transparent transparent $white-alpha-7;
        border-width: 0 5px 5px;
      }
    }
  }

  .ng-select-container {
    align-items: center;

    background-color: $grey-dark-dk-alpha-6;
    border: $ng-select-border;
    border-radius: 4px;

    line-height: $ng-select-line-height;
    padding: remy(5) remy(5) remy(5) remy(15);

    transition: border-color 0.3s ease;

    &:focus-within {
      border: 1px solid $primary;
    }
  }

  .ng-has-value .ng-placeholder {
    display: none;
  }

  .ng-placeholder {
    color: $white-alpha-5;
  }

  .ng-input {
    top: 1px;

    input {
      box-sizing: border-box !important;

      &.input--sm {
        padding: remy(5) remy(15) !important;
      }
    }
  }

  .ng-value-container {
    align-items: center;
    height: 18px;
  }

  .ng-dropdown-panel {
    background-color: $grey-dark-4;
    border: $ng-select-border;
    overflow: hidden;

    &.ng-select-bottom {
      top: 100%;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
      border-top-color: $primary;
      margin-top: -1px;
      .ng-dropdown-panel-items {
        .ng-option {
          &:last-child {
            border-bottom-right-radius: 4px;
            border-bottom-left-radius: 4px;
          }
        }
      }
    }
    &.ng-select-top {
      bottom: 100%;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
      border-bottom-color: $primary;
      margin-bottom: -1px;
      .ng-dropdown-panel-items {
        .ng-option {
          &:first-child {
            border-top-right-radius: 4px;
            border-top-left-radius: 4px;
          }
        }
      }
    }

    .ng-option {
      color: $white-alpha-7;
      line-height: $ng-select-line-height;
      padding: $ng-select-padding;

      &.ng-option-selected {
        color: $white;
        background-color: $grey-medium;
      }

      &.ng-option-marked {
        color: $white;
        background-color: $grey-active;
      }
    }
  }

  span.ng-clear-wrapper {
    display: none;
    color: $white-alpha-7;
    padding: 0 4px;
    width: 20px;
    height: 18px;
  }

  .ng-arrow-wrapper {
    width: 18px;

    .ng-arrow {
      border-color: $white-alpha-7 transparent transparent;
      border-style: solid;
      border-width: 5px 5px 2.5px;
    }
  }
}
