/* Badge
========================================================================== */
// todo GP-19630 rewrite badge styles, get rid of !important
.u-badge {
  display: flex;
  padding: 0 0.6rem;
  font-weight: normal;
  align-items: center;
  font-size: remy(10);
  line-height: remy(20);
  text-transform: uppercase;
  letter-spacing: remy(1.5);
  transition: color $animation-time, border-color $animation-time, background-color $animation-time;
  border: 1px solid transparent;
  background-color: transparent;
  border-radius: $badge-radius;

  .icon {
    margin-right: 0.4rem;
  }

  &-danger {
    background-color: $danger;
    color: white;
  }

  &-ultra-purple-light {
    color: $ultra-purple-light;
    background: $purple-light-transparent;

    .icon {
      background-color: $ultra-purple-light;
    }
  }

  &.hover-effect {
    &:hover {
      cursor: pointer;
      background-color: $white-alpha-12 !important;
      border-color: $white-alpha-12 !important;
      color: $white !important;

      .icon {
        background-color: currentColor;
      }
    }
    &:focus:where(:not([class*='active'])) {
      border-color: transparent;
      outline: solid 1px $grey-silver-light !important;
      outline-offset: 2px;

      .icon {
        background-color: currentColor;
      }
    }
  }

  &-primary {
    color: $ultra-purple;
    border-color: $ultra-purple;

    .icon {
      background-color: $ultra-purple;
    }
    &.hover-effect:hover {
      color: $ultra-purple !important;
      background: $primary-alpha-2 !important;
    }
    &.hover-effect:focus:where(:not([class*='active'])) {
      color: $ultra-purple;
      background: $primary-alpha-2;
    }
  }
}

.badge {
  &.badge-grey-light {
    background-color: $grey-light;
    color: $white-alpha-5;
  }

  &.badge-grey-dark-lt {
    background-color: $grey-dark-lt;
    color: $white-alpha-5;
  }

  &.badge-blue-dark {
    background-color: #4b4458;
    color: $white-alpha-5;
  }

  &.badge-ultra-purple-light-alpha-1 {
    background-color: $ultra-purple-light-transparent;
    color: $ultra-purple-light;
  }

  &.badge-light-green-lt-alpha-1 {
    background-color: $light-green-lt-alpha-1;
    color: $light-green;
  }
}
