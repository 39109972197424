/** @ultraos-graphic-kit **/
@import './vendor/ultraos-graphic-kit/assets/scss/ultra-tools';
@import './vendor/ultraos-graphic-kit/assets/scss/utilities/breakpoints';

/** bootstrap **/
@import './vendor/bootstrap/bootstrap-tools';

@import 'variables/variables';
body {
  z-index: 1;
}
