@import 'libs/design-system/src/lib/foundations/colors/colors';
@import 'libs/assets/scss/vendor/ultraos-graphic-kit/assets/scss/variables';
@import 'libs/assets/scss/vendor/ultraos-graphic-kit/assets/scss/functions';

/* Typography
========================================================================== */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $fontBase;
  margin-top: 0;
  margin-bottom: $headings-margin-bottom;
}

h1 {
  font-weight: bold;
  font-size: $h1-fontsize;
  line-height: remy(40);
  letter-spacing: remy(0.25);
}

h2 {
  font-weight: bold;
  font-size: $h2-fontsize;
  line-height: remy(38);
}

h3 {
  font-weight: bold;
  font-size: $h3-fontsize;
  line-height: remy(34);
}

h4 {
  font-size: $h4-fontsize;
  font-weight: normal;
  line-height: remy(32);
}

h5 {
  font-weight: 900;
  font-size: $h5-fontsize;
  line-height: remy(24);
  letter-spacing: remy(0.15);
}

h6 {
  font-weight: 900;
  font-size: $h6-fontsize;
  line-height: remy(20);
  letter-spacing: remy(0.1);
}

p {
  margin-top: 0;
  font-size: $fontsize-base;
}

a {
  cursor: pointer;
  text-decoration: none;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
  list-style: none;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

small {
  font-size: remy(11);
}

a {
  cursor: pointer;
  transition: color $animation-time;

  &:hover {
    text-decoration: none;
  }

  &.more-link {
    font-size: remy(10);
    line-height: remy(16);
    text-transform: uppercase;

    &.text-primary:hover {
      color: white;
    }
  }

  &.text-white-alpha-7 {
    &:hover {
      color: $white !important;
    }
  }

  &.text-ultra-purple-light {
    &:hover {
      color: $ultra-purple !important;
    }
  }

  &.text-orange-light:hover {
    color: $orange-dark-hover !important;
  }
}

button {
  transition: color $animation-time;
  &.text-white-alpha-7 {
    &[disabled] {
      opacity: 0.5;
      cursor: not-allowed;
    }
    &:not([disabled]) {
      &:hover {
        color: $white !important;
      }
    }
  }
}

.no-underline {
  &,
  &:hover {
    text-decoration: none;
  }
}

.rarefied-text {
  font-size: remy(10);
  line-height: remy(18);
  color: $white-alpha-5;
  letter-spacing: remy(1.5);
  text-transform: uppercase;
}

.text {
  font-size: remy(12);
  color: $white-alpha-5;
  letter-spacing: remy(0.25);
}

.ls-quoter {
  letter-spacing: $ls-quoter;
}

.ls-one-and-half {
  letter-spacing: $ls-one-and-half;
}

.text-shadow {
  text-shadow: 0 0 remy(8) $grey-dark-dk-alpha-5;
}

.text-justify {
  text-align: justify;
}

.text-transform-none {
  text-transform: none !important;
}

.word-break {
  word-break: break-word;
}

.white-space-break {
  white-space: break-spaces;
}

.white-space-pre-line {
  white-space: pre-line;
}

.white-space-nowrap {
  white-space: nowrap;
}
