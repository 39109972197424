@import '../vendor-tools';

.noUi-horizontal {
  height: 4px;
  box-shadow: none;
  border: none;
  border-radius: 0;

  .noUi-handle-lower {
    right: -10px !important;
  }

  .noUi-handle-upper {
    right: -10px !important;
  }

  .noUi-handle {
    width: 13px;
    height: 13px;
    border-radius: 50%;
    top: -5px;
    border: 2px solid $grey;
    background-color: $white;
    cursor: grab;
    box-shadow: none;

    &:after,
    &:before {
      content: none;
    }
  }
}

.noUi-target {
  background-color: $white-alpha-12;
}

.noUi-connect {
  background-color: $ultra-purple-light;
}

.disabled .noUi-handle {
  display: none;
}

.disabled .noUi-connect {
  background-color: $white-alpha-25;
}

.disabled.danger .noUi-connects {
  background-color: rgba($danger, 0.5);
}

.noUi-connects {
  cursor: pointer;
}
