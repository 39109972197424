/* Box Shadow
========================================================================== */

.shadow-center-sm {
  box-shadow: 0 0 2px $shadow-color;
}

.shadow-center {
  box-shadow: 0 0 4px $shadow-color;
}

.shadow-center-lg {
  box-shadow: 0 0 8px $shadow-color;
}

.shadow-bottom {
  box-shadow: 0 2px 4px $shadow-color;
}

.shadow-right {
  box-shadow: 4px 0 2px $shadow-color;
}

.shadow-light-center {
  box-shadow: 0 0 4px $shadow-light-color;
}

.shadow-light-center-lg {
  box-shadow: 0 0 8px $shadow-light-color;
}
