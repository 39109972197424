/*
utility function
get a color value from colors schemes
*/
@function get_color_scheme($name, $tone: dark, $key: root) {
  $color_: map-get($colors_schemes, $name);
  $color_tone: map-get($color_, $tone);
  @return map-get($color_tone, $key);
}
@function get_color($name, $tone: dark, $key: root) {
  @return get_color_scheme($name, $tone: dark, $key: root);
}
