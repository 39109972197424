.img-placeholder {
  background-color: $grey-dark-dk-alpha-5;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.full-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  transition: opacity $animation-time;
  background-color: $grey-dark-alpha-2;

  &--fade-in {
    animation-name: showFading;
    animation-delay: 0.4s;
    animation-duration: 0.75s;
    animation-fill-mode: both;
  }
}

.grayscale {
  filter: grayscale(100%);
}
