// Typography

$fontBase: 'Inter Ultra', sans-serif;
$fontBase-mono: 'Roboto Ultra Mono', monospace;

$fontsize-base: 14px !default; // 14px
$line-height-base: 1.5 !default;
$headings-margin-bottom: 0.5rem !default; // 8px

// Letter spacing
$ls-quoter: remy(0.25) !default;
$ls-one-and-half: remy(1.5) !default;

// Header Styles
$h1-fontsize: remy(34) !default;
$h2-fontsize: remy(30) !default;
$h3-fontsize: remy(28) !default;
$h4-fontsize: remy(24) !default;
$h5-fontsize: remy(20) !default;
$h6-fontsize: remy(14) !default;

// Navbar
$nav-height: 50px;

// Buttons
$btn-font-size: 0.75rem !default; // 12px
$btn-icon-font-size: 1.3rem !default;
$btn-height: 40px !default;
$border-radius: 0.25rem !default; // 4px
$btn-border: 1px !default;
$btn-font-weight: bold !default;

// Alerts
$alert-padding-y: 0.75rem !default; // 12px
$alert-padding-x: 1.25rem !default; // 20px
$alert-margin-bottom: 1rem !default; // 16px

// Utilities
$border-width: 1px !default;
$border-default-color: $grey-dividers;
$shadow-color: $black-alpha-3;
$shadow-light-color: $black-alpha-14;
$badge-radius: 100px;

// Animation
$animation-time: 0.3s ease;
