.custom-block-scroll {
  overflow: hidden;
}

.overlay-backdrop {
  background-color: $black-30;
}

.blur-32 {
  backdrop-filter: blur(32px);
}
