/* Opacity
========================================================================== */

.opacity-0 {
  opacity: 0;
}

.opacity-01 {
  opacity: 0.1;
}

.opacity-02 {
  opacity: 0.2;
}

.opacity-03 {
  opacity: 0.3;
}

.opacity-07 {
  opacity: 0.3;
}

.opacity-1 {
  opacity: 1;
}
