@import './vendor/bootstrap/bootstrap-tooltip';

// todo GP-21595 swiper move to separate file
@import 'swiper/scss';
@import 'swiper/scss/navigation';
@import 'swiper/scss/pagination';

@import './spinner/btn';

.ultra-blur-backdrop {
  background: $grey-dark-alpha-6;
  backdrop-filter: blur(3px);
}
.cdk-global-overlay-wrapper {
  overflow-y: auto;
}
