@import 'variables';

@each $color, $value in $theme-colors {
  .text-#{$color} {
    color: $value !important;
  }
}

.text-white {
  color: $white !important;
}

.text-white-alpha-7 {
  color: $white-alpha-7 !important;
}

.text-white-alpha-50 {
  color: $white-50 !important;
}

.text-white-alpha-87 {
  color: $white-alpha-87 !important;
}

.text-white-alpha-6 {
  color: $white-alpha-6 !important;
}

.text-white-alpha-5 {
  color: $white-alpha-5 !important;
}

.text-white-alpha-3 {
  color: $white-alpha-3 !important;
}

.text-ultra-purple-light {
  color: $ultra-purple-light !important;
}

.text-green {
  color: $success !important;
}

.text-yellow-bright {
  color: $warning !important;
}

.text-secondary-yellow-light {
  color: $secondary-yellow-light !important;
}

.text-red-light {
  color: $red-light !important;
}

.text-danger {
  color: $danger !important;
}

.text-light-green {
  color: $light-green !important;
}

.text-green-light {
  color: $green-light !important;
}

.text-orange-light {
  color: $orange-light !important;
}

// background color

.bg-purple-dark {
  background-color: $purple-dark !important;
}

.bg-info {
  background-color: $info !important;
}

.bg-primary {
  background-color: $primary !important;
}
.bg-white-5 {
  background-color: $white-5 !important;
}
.bg-white-50 {
  background-color: $white-50 !important;
}
.bg-white-70 {
  background-color: $white-70 !important;
}
.bg-white-100 {
  background-color: $white-100 !important;
}
.bg-grey-light-1 {
  background-color: $grey-light-1 !important;
}
.bg-grey-light-2 {
  background-color: $grey-light-2 !important;
}
.bg-orange-light {
  background-color: $orange-light !important;
}
.bg-red-light {
  background-color: $red-light !important;
}
.bg-ultra-purple-light {
  background-color: $ultra-purple-light !important;
}
.bg-green-light {
  background-color: $green-light !important;
}
.bg-green-light-alpha-1 {
  background-color: $green-light-transparent !important;
}

.bg-ultra-purple-light-alpha-1 {
  background-color: $ultra-purple-light-transparent !important;
}

.bg-grey-medium-dark {
  background-color: $grey-dark-2-standard-bg !important;
}

// grey
.bg-grey-dark {
  background-color: $grey-dark !important;
}

.bg-grey-dark-3 {
  background-color: $grey-dark-3 !important;
}

.grey-dark-alpha-4 {
  background-color: $grey-dark-alpha-4 !important;
}

.bg-grey-medium-alpha-8 {
  background-color: $grey-medium-alpha-8 !important;
}

.bg-grey {
  background-color: $grey !important;
}

.bg-grey-light {
  background-color: $grey-light !important;
}

.bg-grey-silver {
  background-color: $grey-silver !important;
}

.bg-grey-medium {
  background-color: $grey-medium;
}

// white
.bg-white-alpha-12 {
  background-color: $white-alpha-12 !important;
}

.bg-white-alpha-25 {
  background-color: $white-alpha-25 !important;
}

.bg-white-alpha-50 {
  background-color: $white-50 !important;
}

.bg-white-alpha-7 {
  background-color: $white-alpha-7 !important;
}

.bg-white-alpha-5 {
  background-color: $white-alpha-5 !important;
}

.bg-white-alpha-1 {
  background-color: $white-alpha-1 !important;
}

.bg-white-alpha-3 {
  background-color: $white-alpha-3 !important;
}

.bg-white {
  background-color: $white !important;
}

.bg-light {
  background-color: $light !important;
}

.bg-red {
  background-color: $red !important;

  &-light {
    background-color: $red-light !important;
  }
}

.bg-secondary-yellow-light {
  background-color: $secondary-yellow-light !important;
}

.bg-yellow-light-transparent {
  background-color: $yellow-light-transparent !important;
}

// border color
.border-white-alpha-12 {
  border-color: $white-alpha-12 !important;
}

.border-white-alpha-3 {
  border-color: $white-alpha-3 !important;
}

.border-white-alpha-7 {
  border-color: $white-alpha-7 !important;
}

.border-white-alpha-1 {
  border: 1px solid $white-alpha-1;
}

.border-bottom-white-alpha-1 {
  border-bottom: 1px solid $white-alpha-1;
}

.border-top-white-alpha-1 {
  border-top: 1px solid $white-alpha-1;
}

.border-grey {
  border: 1px solid $grey-dividers;
}

.border-divider {
  &-bottom {
    border-bottom: 1px solid $grey-dividers;
  }
}

.gap-8 {
  gap: 8px;
}

.gap-16 {
  gap: 16px;
}

.gap-32 {
  gap: 32px;
}

.background-transparent {
  background: transparent !important;
}

.transition-opacity {
  transition: opacity 0.2s ease-out;
}

.btn-close {
  &--l {
    width: 40px;
    height: 40px;
  }
}

.border-box {
  box-sizing: border-box;
}

.mr-12-n {
  margin-right: -12px;
}
