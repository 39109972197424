/* Buttons
========================================================================== */

.btn {
  font-weight: $btn-font-weight;
  font-family: $fontBase;
  text-transform: capitalize;

  &[disabled] {
    cursor: default;
  }

  &-min-size-120 {
    min-width: 120px;
  }

  &-fixed-size {
    min-width: 150px;
  }

  &-medium-dark {
    color: $white !important;
    border-color: $grey !important;
    background: $grey !important;

    &:hover {
      border-color: $grey-light !important;
      background: $grey-light !important;
    }
  }

  &-warning {
    color: $dark-medium-emphasis !important;
    background-color: $warning !important;
  }

  &-danger {
    color: $white !important;
    background-color: $danger !important;
  }

  &-light {
    background: $white-alpha-1 !important;
    color: $white !important;
    border: none !important;

    &:hover {
      background: $white-alpha-2 !important;
    }
  }

  &-icon {
    &-left {
      padding-left: remy(35);

      .icon {
        left: remy(9);
      }
    }

    &-right {
      padding-right: remy(35);

      .icon {
        right: remy(9);
      }
    }
  }

  &-transparent,
  &-transparent:hover,
  &-transparent:focus {
    color: $white;
    background: transparent !important;
    border: transparent !important;
  }

  &-ultra-purple-light {
    color: $white;
    background-color: $ultra-purple-light;

    .icon {
      background-color: $white;
    }

    &:hover {
      background-color: $ultra-purple;
    }
  }

  &-purple {
    color: $white;
    background-color: $ultra-purple;

    .icon {
      background-color: $white;
    }

    &:hover {
      background-color: $ultra-purple-light;
    }
  }

  &-bright-link {
    color: $ultra-purple-light;
    background-color: transparent;
    transition: $animation-time color;

    &:hover {
      color: $ultra-purple;
    }
  }

  &-red-light {
    color: $red-light;
    background-color: transparent;
    transition: $animation-time color;
    -webkit-appearance: none;
    border: none;
    &:hover,
    &:focus {
      color: $red-light-hover;
    }
    &:active {
      color: $red-light-active;
    }
  }

  &-transparent-light {
    color: $white;
    background-color: $white-alpha-1;

    &:hover,
    &:focus {
      color: $white;
      background-color: $white-alpha-2;
    }

    &:active {
      color: $white;
      background-color: $white-alpha-3;
    }

    &:disabled {
      color: $white-alpha-25;
      background-color: $white-alpha-1;
    }
  }

  &-transparent-purple {
    color: $ultra-purple-light;
    background-color: $ultra-purple-light-transparent;

    &:hover,
    &:focus {
      color: $ultra-purple-light;
      background-color: $ultra-purple-light-transparent-hover;
    }

    &:active {
      background-color: $ultra-purple-light-transparent-active;
    }

    &:disabled {
      background-color: $ultra-purple-light-transparent;
    }
  }

  &-orange-light {
    color: $orange-light;
    background-color: $orange-light-transparent;

    &:hover,
    &:focus {
      color: $orange-light;
      background-color: $orange-light-transparent-hover;
    }

    &:active {
      background-color: $orange-light-transparent-active;
    }

    &:disabled {
      background-color: $orange-light-transparent-disabled;
    }
  }
}

[class*='btn-icon-'] {
  position: relative;

  .icon {
    top: 50%;
    position: absolute;
    transform: translateY(-50%) !important;

    &.icon-arrow-right {
      transform: translateY(-50%) rotate(180deg) !important;
    }

    &.icon-arrow-small-left {
      transform: translateY(-50%) rotate(180deg) !important;
    }
  }
}
