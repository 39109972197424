/* Animation
========================================================================== */

.transition {
  transition: all $animation-time;
}

.transition-bg {
  transition: background-color $animation-time;
}

.transition-opacity {
  transition: opacity $animation-time;
}

.animate-rotate {
  animation: rotatePlane 3.5s infinite ease-in-out;
}

.animate-rotate-circle {
  animation: rotate-circle 1.25s linear infinite;
}

.animate-showFading {
  animation: showFading $animation-time;
}

.skeleton-screen {
  @include skeletonScreen();
}
