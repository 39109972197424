$path_to_icons: 'apps/ultra-store/src/assets/scss/icons/svg/';

i.icon {
  /* icons */
  &-arrow-small {
    -webkit-mask: url($path_to_icons + 'arrow-small.svg') no-repeat 50% 50%;
    min-width: 17px;
    min-height: 17px;
  }

  &-attention {
    -webkit-mask: url($path_to_icons + 'attention.svg') no-repeat 50% 50%;
    min-width: 26px;
    min-height: 26px;
  }

  &-delete-user {
    -webkit-mask: url($path_to_icons + 'delete-user.svg') no-repeat 50% 50%;
    min-width: 24px;
    min-height: 24px;
  }

  &-x-circle {
    -webkit-mask: url($path_to_icons + 'x-circle.svg') no-repeat 50% 50%;
    min-height: 16px;
    min-width: 16px;
  }

  &-sign-out {
    -webkit-mask: url($path_to_icons + 'sign-out.svg') no-repeat 50% 50%;
    min-width: 24px;
    min-height: 24px;
  }

  &-exist {
    -webkit-mask: url($path_to_icons + 'exist.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }

  &-screen {
    -webkit-mask: url($path_to_icons + 'screen.svg') no-repeat 50% 50%;
    min-width: 24px;
    min-height: 24px;
  }

  &-world {
    -webkit-mask: url($path_to_icons + 'geofencing-world.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;

    &-xxl {
      -webkit-mask: url($path_to_icons + 'geofencing-world-xxl.svg') no-repeat 50% 50%;
      min-width: 135px !important;
      min-height: 135px !important;
    }
  }

  &-world-na {
    -webkit-mask: url($path_to_icons + 'geofencing-na-world.svg') no-repeat 50% 50%;
    min-width: 16px;
    min-height: 16px;
  }
}
