// overwrite already defined variable if any (currently used by browser-extension-wallet)
$path_to_fonts: '../fonts/' !default;

/* Font-face
========================================================================== */

@font-face {
  font-family: 'Roboto Ultra Mono';
  font-style: normal;
  font-weight: 400;
  src: url($path_to_fonts + 'RobotoUltraMono-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Roboto Ultra Mono';
  font-style: normal;
  font-weight: 500;
  src: url($path_to_fonts + 'RobotoUltraMono-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'Roboto Ultra Mono';
  font-style: normal;
  font-weight: 700;
  src: url($path_to_fonts + 'RobotoUltraMono-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'Inter Ultra';
  font-style: normal;
  font-weight: 400;
  src: url($path_to_fonts + 'InterUltra-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Inter Ultra';
  font-style: normal;
  font-weight: 500;
  src: url($path_to_fonts + 'InterUltra-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'Inter Ultra';
  font-style: normal;
  font-weight: 700;
  src: url($path_to_fonts + 'InterUltra-Bold.otf') format('opentype');
}
