.overflow-visible {
  overflow: visible;
}

// TODO: remove when we adjust designs to the new xxl breakpoint.
@include media-breakpoint-up('xxl') {
  .container {
    max-width: 1140px !important;
  }
}
