.input,
.textarea {
  color: $white;
  font-size: remy(12);
  font-family: $fontBase;
  letter-spacing: $ls-quoter;
  border-radius: $border-radius;
  background: $black-alpha-2;
  transition: border-color $animation-time;

  &:focus,
  &.focus {
    border-color: $primary;
  }

  &--invalid,
  &.focus.invalid,
  &.invalid {
    caret-color: $danger;
    border-color: $danger !important;
  }

  &:disabled {
    background: $grey-dark-2-standard-bg;
    pointer-events: none;

    &::placeholder {
      color: $white-alpha-2;
    }
  }

  &:hover:not(:focus) {
    border: 1px solid $white-alpha-5;

    &::placeholder {
      color: $white-alpha-5 !important;
    }
  }

  &::placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: remy(12);
    line-height: inherit;
    letter-spacing: remy(0.25);
    color: $white-alpha-3;
  }
}

.input {
  border: 1px solid $white-alpha-1;

  &--sm {
    line-height: remy(18);
    padding: remy(5) remy(15);
  }

  &--lg {
    padding: remy(12.5) remy(15);
  }

  &.no-border {
    border-color: transparent;
  }

  &::-webkit-input-placeholder {
    color: $white-alpha-3;
  }

  &:-webkit-autofill {
    animation-name: autofill-dark;
    animation-fill-mode: both;
  }

  &__wrapper {
    position: relative;
  }

  &__icon {
    width: 32px;
    height: 22px;
    left: remy(10);
    cursor: default;
  }

  &-icon {
    padding-left: 62px;
  }

  &__eye {
    top: 50%;
    right: remy(10);
    cursor: pointer;
    position: absolute;
    transform: translateY(-50%);
    background-color: transparent;

    &--active {
      background-color: $white-alpha-5;
    }

    &.input__eye--active:hover {
      background-color: $white;
    }
  }
}

.input,
.datepicker {
  &__icon {
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
  }
}

.datepicker {
  &__icon {
    right: remy(10);
  }
}

.textarea {
  width: 100%;
  min-height: 80px;
  padding: remy(15);
  border: 1px solid $white-alpha-1;

  &-sm {
    min-height: 80px;
  }

  &-lg {
    min-height: 135px;
  }
}

.maxLen {
  right: remy(10);
  bottom: remy(6);
  position: absolute;
  font-size: remy(11);
  line-height: remy(18);
  color: $white-alpha-3;
  text-transform: uppercase;
}

.fp-l {
  padding-left: remy(8);
}

.fp-r {
  padding-right: remy(8);
}

.fw-fc {
  width: fit-content;
}
