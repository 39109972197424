/* Alert
========================================================================== */

.u-alert {
  position: relative;
  padding: $alert-padding-y $alert-padding-x;
  margin-bottom: $alert-margin-bottom;
  letter-spacing: $ls-quoter;
  border-radius: $border-radius;

  > p {
    margin-bottom: 0;
  }

  &-primary {
    color: $white;
    background-color: $ultra-purple;
  }

  &-info {
    color: $white-alpha-7 !important;
    background-color: $info;
  }

  &-warning {
    color: $grey-dark !important;
    background-color: $warning;
  }

  &-danger {
    color: $white !important;
    background-color: $danger;
  }
}
