@import 'designtoken';

// missing in zeroheight token. added manually :
$ultra-purple-light: #c5abff;
$white-500: rgba(255, 255, 255, 0.05);
$yellow-light: rgb(246, 230, 167);
$black-500: rgba(0, 0, 0, 0.05);

$purple-dark: #908bd5;
$purple-dark-active: #827dc0;
$purple-dark-disabled: #48466b;
$purple-dark-hover: #9b96d9;
$purple-dark-transparent: rgba(144, 139, 213, 0.1);
$purple-dark-transparent-active: rgba(144, 139, 213, 0.3);
$purple-dark-transparent-disabled: rgba(144, 139, 213, 0.05);
$purple-dark-transparent-hover: rgba(144, 139, 213, 0.2);

$purple-light-transparent: rgba(179, 173, 255, 0.1);

// no brown anymore in DT ?
$brown-dark: #f28166;
$brown-dark-active: #cc6f24;
$brown-dark-disabled: #975d56;
$brown-dark-hover: #f38d75;
$brown-dark-transparent: rgba(225, 112, 85, 0.1);
$brown-dark-transparent-active: rgba(225, 112, 85, 0.3);
$brown-dark-transparent-disabled: rgba(225, 112, 85, 0.05);
$brown-dark-transparent-hover: rgba(225, 112, 85, 0.2);
$brown-light: #ffa894;
$brown-light-active: #e5ad7f;
$brown-light-disabled: #9e706d;
$brown-light-hover: #ffb19e;
$brown-light-transparent: rgba(250, 177, 160, 0.1);
$brown-light-transparent-active: rgba(250, 177, 160, 0.3);
$brown-light-transparent-disabled: rgba(250, 177, 160, 0.05);
$brown-light-transparent-hover: rgba(250, 177, 160, 0.2);

// old to clean/change all matches..
// white
$white: $white-100;
$white-alpha-9: rgba(255, 255, 255, 0.9);
$white-alpha-7: rgba(255, 255, 255, 0.7);
$white-75: rgba(255, 255, 255, 0.75);
$white-alpha-75: rgba(255, 255, 255, 0.75);

$white-alpha-6: rgba(255, 255, 255, 0.6);
$white-alpha-5: $white-50;
$white-alpha-3: $white-30;
$white-alpha-1: $white-10;
$white-alpha-12: rgba(255, 255, 255, 0.12);
$white-alpha-2: $white-20;
$white-alpha-25: rgba(255, 255, 255, 0.25);
$white-alpha-87: rgba(255, 255, 255, 0.87);
$white-alpha-05: rgba(255, 255, 255, 0.05);

// black
$black: $black-100;
$black-alpha-7: rgba(0, 0, 0, 0.7); // not used
$black-alpha-5: $black-50;
$black-alpha-3: $black-30;
$black-alpha-2: $black-20;
$black-alpha-1: $black-10;
$black-alpha-12: rgba(0, 0, 0, 0.12); // black-12
$black-alpha-14: rgba(0, 0, 0, 0.14); // black-14
$black-alpha-22: rgba(0, 0, 0, 0.22); // not used
$black-alpha-25: rgba(0, 0, 0, 0.25); // not used

// legacy greys
$grey-dark-dk: rgb(37, 34, 41); // #252229
$grey-dark-medium: rgb(40, 38, 44); // #28262C
$grey-dark-dk-alpha-8: rgba(37, 34, 41, 0.8);
$dark-medium-emphasis: rgba(37, 34, 41, 0.87);
$grey-dark-dk-alpha-7: rgba(37, 34, 41, 0.7);
$grey-dark-dk-alpha-6: rgba(37, 34, 41, 0.6);
$grey-dark-dk-alpha-5: rgba(37, 34, 41, 0.5);
$grey-dark-dk-alpha-38: rgba(37, 34, 41, 0.38);
$grey-dark-dk-alpha-1: rgba(37, 34, 41, 0.1);

$grey-dark: rgb(48, 44, 54); // #302C36
$grey-dark-alpha-0: rgba(48, 44, 54, 0);
$grey-dark-alpha-1: rgba(48, 44, 54, 0.1);
$grey-dark-alpha-2: rgba(48, 44, 54, 0.2);
$grey-dark-alpha-3: rgba(48, 44, 54, 0.3);
$grey-dark-alpha-4: rgba(48, 44, 54, 0.4);
$grey-dark-alpha-5: rgba(48, 44, 54, 0.5);
$grey-dark-alpha-6: rgba(48, 44, 54, 0.6);

$grey-medium: rgb(71, 67, 81); //#474351
$grey-alpha-4: rgba(73, 69, 84, 0.4);

$grey-medium: rgb(60, 56, 70); // #3C3846 > value has new name in design_system : $grey
$grey-medium-alpha-8: rgba(60, 56, 70, 0.8);
$grey-medium-alpha-7: rgba(60, 56, 70, 0.7);
$grey-medium-alpha-5: rgba(60, 56, 70, 0.5);

$grey-medium-dark: rgb(49, 45, 54); // #312D36
$grey-medium-dark-alpha-0: rgba(49, 45, 54, 0);
$grey-medium-dark-alpha-1: rgba(49, 45, 54, 0.1);
$grey-medium-dark-alpha-4: rgba(49, 45, 54, 0.4);
$grey-medium-dark-alpha-5: rgba(49, 45, 54, 0.5);
$grey-medium-dark-alpha-6: rgba(49, 45, 54, 0.6);
$grey-medium-dark-alpha-8: rgba(49, 45, 54, 0.8);

$grey-dark-lt: rgb(52, 58, 64); // #343A40
$grey-light: rgb(105, 100, 117); // #696475
$grey-silver: rgb(190, 189, 191);
$grey-silver-light: rgb(168, 167, 169); // #A8A7A9

$light-green: rgb(140, 232, 149);
$light-green-lt: rgb(144, 238, 144);
$light-green-lt-alpha-1: rgba(144, 238, 144, 0.1);

$dark-green: rgb(68, 73, 78); // one use

$red: rgb(218, 34, 48); // #DA2230

// ultra
$primary: $ultra-purple;
$primary-disabled: $ultra-purple-disabled;
$primary-alpha-1: rgba($primary, 0.1);
$primary-alpha-2: rgba($primary, 0.2);
$primary-alpha-7: rgba(
  $primary,
  0.7
); // to finalize without hardcoded value (need scss import reordering to be after design_system variable definitions)

// bootstrap customization
$muted: $grey-silver;

$warning: rgb(255, 234, 167); // #FFEAA7
$remind: rgb(254, 174, 129); // #FEAE81
$danger: rgb(255, 92, 95); // #FF5C5F
$success: #0ecc9e; // #0ECC9E
$info: rgb(110, 92, 154); // #6E5C9A
$text-muted: rgb(168, 167, 169); // #A8A7A9
$light: rgb(249, 250, 251); // #f9fafb
$green: rgb(118, 245, 154); //#76f59a

// tools kit
$text-disabled: $white-50;

$uniq-border: (
  'common': $_04-uniq-border-common,
  'common-transparent': $_04-uniq-border-common-transparent,
  'epic': $_04-uniq-border-epic,
  'epic-transparent': $_04-uniq-border-epic-transparent,
  'legendary': $_04-uniq-border-legendary,
  'legendary-transparent': $_04-uniq-border-legendary-transparent,
  'rare': $_04-uniq-border-rare,
  'rare-transparent': $_04-uniq-border-rare-transparent,
  'uncommon': $_04-uniq-border-uncommon,
  'uncommon-transparent': $_04-uniq-border-uncommon-transparent,
);

/* bootstrap custom color */
$theme-colors: (
  'primary': $primary,
  'muted': $muted,
  'warning': $warning,
  'white': $white,
  'danger': $danger,
  'info': $info,
  'success': $success,
  'text-muted': $text-muted,
);

$btn-primary-color: $white !important;
$btn-white-color: #a481f0 !important;
$greyish: rgb(155, 153, 161); // #9B99A1
