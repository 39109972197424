ngb-datepicker {
  min-width: 0 !important;
  //max-width: 240px !important;
  z-index: 1051 !important;

  &.dropdown-menu {
    background-color: $grey-dark-dk;
    border: none;
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2), 0 3px 14px rgba(0, 0, 0, 0.12), 0 8px 10px rgba(0, 0, 0, 0.14);
  }

  .ngb-dp-header,
  .ngb-dp-weekdays {
    background-color: $grey-dark-dk !important;
  }

  .ngb-dp-day > div,
  .ngb-dp-weekday {
    font-size: remy(12);
    color: $white-alpha-7 !important;
  }

  .form-select {
    cursor: pointer;
    background: transparent !important;
    border-color: transparent;
    color: $white-alpha-5;

    &:focus {
      border-color: transparent;
      color: $white;
    }

    option {
      background-color: $grey-dark-dk;
      color: $white-alpha-5;
      position: relative;

      &:checked:after {
        content: attr(title);
        background: #666;
        color: #fff;
        position: absolute;
        width: 100%;
        left: 0;
        border: none;
      }
    }
  }

  .ngb-dp-day > div {
    &:hover,
    &:active {
      background-color: $grey !important;
      border-color: $grey !important;
    }
  }

  .ngb-dp-arrow-btn {
    color: $white-alpha-7;

    &:hover {
      color: $white;
    }
  }
}
