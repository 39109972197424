@import 'libs/design-system/src/lib/foundations/colors/colors';

.toast-success {
  background: $success !important;
}

.toast-danger {
  background: $danger !important;
}

.toast-info {
  background: $info !important;
}
.toast-container {
  max-width: calc(100vw - 32px);
}
