$grid-col-count: 24;
$grid-col-class-slug: grid-col !default;

.grid-container {
  display: grid;
  grid-template-columns: repeat($grid-col-count, 1fr);
  grid-gap: 32px;
  padding: 64px;
  max-width: 100%;
  width: auto;
}

// Available from 0 to 24
@for $i from 1 through $grid-col-count {
  .#{$grid-col-class-slug}-#{$i} {
    grid-column: span $i;
  }
}
