@import '../tools';

.custom-scroll {
  overflow-y: auto;
  overflow-x: hidden;
  transition: all 0.3s ease;
  &::-webkit-scrollbar {
    width: 4px;
    &-track,
    &-track-piece {
      background: none;
      box-shadow: none;
    }

    &-thumb {
      border-radius: 4px;
    }
  }
  &--no-animation {
    &::-webkit-scrollbar {
      &-thumb {
        border-radius: 4px;
        opacity: 1;
        background: rgba(255, 255, 255, 0.1);
      }
    }
  }
  &--hidden {
    &::-webkit-scrollbar-thumb {
      background-color: transparent;
      opacity: 0;
    }
  }
  &--top-gradient:before,
  &--bottom-gradient:after {
    content: '';
    position: sticky;
    width: 100%;
    height: 24px;
    flex-shrink: 0;
    left: 0;
    display: block;
    transition: opacity 0.3s ease-out;
    pointer-events: none;
    touch-action: none;
    z-index: 1;
  }
  &--top-gradient {
    &:before {
      top: -1px;
      margin-bottom: -24px;
      background: linear-gradient(to bottom, #3c3846 0, rgba(60, 56, 70, 0) 100%);
    }
    &-hidden:before {
      opacity: 0;
    }
  }
  &--bottom-gradient {
    &:after {
      bottom: -1px;
      margin-top: -24px;
      background: linear-gradient(to top, #3c3846 0, rgba(60, 56, 70, 0) 100%);
    }
    &-hidden:after {
      opacity: 0;
    }
  }
  &--dark {
    &.custom-scroll--top-gradient {
      &:before {
        background: linear-gradient(to bottom, #252229 0, rgba(37, 34, 41, 0) 100%);
      }
    }
    &.custom-scroll--bottom-gradient {
      &:after {
        background: linear-gradient(to top, #252229 0, rgba(37, 34, 41, 0) 100%);
      }
    }
  }
  &--grey-medium-dark {
    &.custom-scroll--top-gradient {
      &:before {
        background: linear-gradient(
          to bottom,
          $grey-dark-2-standard-bg 0,
          rgba($grey-dark-2-standard-bg, 0) 100%
        );
      }
    }
    &.custom-scroll--bottom-gradient {
      &:after {
        background: linear-gradient(
          to top,
          $grey-dark-2-standard-bg 0,
          rgba($grey-dark-2-standard-bg, 0) 100%
        );
      }
    }
  }
}
