* {
  outline-color: transparent !important;
}

html,
body {
  height: 100%;
}

body {
  width: 100%;
  padding: 0 !important;
  color: #ffffff;
  font-size: $fontsize-base;
  font-family: $fontBase;
}

body.fixed {
  overflow: hidden;
}

.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: 0 0 0 !important;
}

textarea:focus,
input:focus {
  outline: none !important;
}

*:not(:where(ultra-dialog-container)):focus {
  outline: none !important;
  box-shadow: initial !important;
}

ul {
  margin: 0;
  padding: 0;
}

hr {
  opacity: 1;
}

.w-30 {
  width: 30%;
}

.d-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload-errors {
  width: 180px;

  span {
    display: block;
  }
}
