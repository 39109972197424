// https://figs.zeroheight.com/api/design_tokens?auth=492a2QhpDeIoVQhYN7vEUA&format=sass&id=21706&dl=1

/**** Fonts part ****/
/** b1-bold **/
$b1-bold-font-family: 'Inter Ultra', 'InterUltra-Bold';
$b1-bold-font-size: 16px;
$b1-bold-letter-spacing: 0px;
$b1-bold-line-height: 24px;
$b1-bold-font-weight: 700;
$b1-bold-color: black;

@mixin b1-bold() {
  font-family: $b1-bold-font-family;
  font-size: $b1-bold-font-size;
  letter-spacing: $b1-bold-letter-spacing;
  line-height: $b1-bold-line-height;
  font-weight: $b1-bold-font-weight;
}
/** b1-mono-bold **/
$b1-mono-bold-font-family: 'Roboto Ultra Mono', 'RobotoUltraMono-Bold';
$b1-mono-bold-font-size: 16px;
$b1-mono-bold-letter-spacing: 0px;
$b1-mono-bold-line-height: 24px;
$b1-mono-bold-font-weight: 700;
$b1-mono-bold-color: black;

@mixin b1-mono-bold() {
  font-family: $b1-mono-bold-font-family;
  font-size: $b1-mono-bold-font-size;
  letter-spacing: $b1-mono-bold-letter-spacing;
  line-height: $b1-mono-bold-line-height;
  font-weight: $b1-mono-bold-font-weight;
}
/** b1-regular **/
$b1-regular-font-family: 'Inter Ultra', 'InterUltra-Regular';
$b1-regular-font-size: 16px;
$b1-regular-letter-spacing: 0px;
$b1-regular-line-height: 24px;
$b1-regular-font-weight: 400;
$b1-regular-color: black;

@mixin b1-regular() {
  font-family: $b1-regular-font-family;
  font-size: $b1-regular-font-size;
  letter-spacing: $b1-regular-letter-spacing;
  line-height: $b1-regular-line-height;
  font-weight: $b1-regular-font-weight;
}
/** b2-bold **/
$b2-bold-font-family: 'Inter Ultra', 'InterUltra-Bold';
$b2-bold-font-size: 12px;
$b2-bold-letter-spacing: 0px;
$b2-bold-line-height: 20px;
$b2-bold-font-weight: 700;

@mixin b2-bold() {
  font-family: $b2-bold-font-family;
  font-size: $b2-bold-font-size;
  letter-spacing: $b2-bold-letter-spacing;
  line-height: $b2-bold-line-height;
  font-weight: $b2-bold-font-weight;
}
/** b2-mono-bold **/
$b2-mono-bold-font-family: 'Roboto Ultra Mono', 'RobotoUltraMono-Bold';
$b2-mono-bold-font-size: 12px;
$b2-mono-bold-letter-spacing: 0px;
$b2-mono-bold-line-height: 20px;
$b2-mono-bold-font-weight: 700;

@mixin b2-mono-bold() {
  font-family: $b2-mono-bold-font-family;
  font-size: $b2-mono-bold-font-size;
  letter-spacing: $b2-mono-bold-letter-spacing;
  line-height: $b2-mono-bold-line-height;
  font-weight: $b2-mono-bold-font-weight;
}
/** b2-regular **/
$b2-regular-font-family: 'Inter Ultra', 'InterUltra-Regular';
$b2-regular-font-size: 12px;
$b2-regular-letter-spacing: 0px;
$b2-regular-line-height: 20px;
$b2-regular-font-weight: 400;

@mixin b2-regular() {
  font-family: $b2-regular-font-family;
  font-size: $b2-regular-font-size;
  letter-spacing: $b2-regular-letter-spacing;
  line-height: $b2-regular-line-height;
  font-weight: $b2-regular-font-weight;
}
/** bold **/
$bold-font-family: 'Inter Ultra', 'InterUltra-Bold';
$bold-font-size: 11px;
$bold-letter-spacing: 0px;
$bold-line-height: 16px;
$bold-font-weight: 700;

@mixin bold() {
  font-family: $bold-font-family;
  font-size: $bold-font-size;
  letter-spacing: $bold-letter-spacing;
  line-height: $bold-line-height;
  font-weight: $bold-font-weight;
}
/** button-1-bold **/
$button-1-bold-font-family: 'Inter Ultra', 'InterUltra-Bold';
$button-1-bold-font-size: 12px;
$button-1-bold-letter-spacing: 0px;
$button-1-bold-line-height: 16px;
$button-1-bold-font-weight: 700;

@mixin button-1-bold() {
  font-family: $button-1-bold-font-family;
  font-size: $button-1-bold-font-size;
  letter-spacing: $button-1-bold-letter-spacing;
  line-height: $button-1-bold-line-height;
  font-weight: $button-1-bold-font-weight;
}
/** button-1-price-medium **/
$button-1-price-medium-font-family: 'Inter Ultra', 'InterUltra-Medium';
$button-1-price-medium-font-size: 12px;
$button-1-price-medium-letter-spacing: 0px;
$button-1-price-medium-line-height: 16px;
$button-1-price-medium-font-weight: 500;

@mixin button-1-price-medium() {
  font-family: $button-1-price-medium-font-family;
  font-size: $button-1-price-medium-font-size;
  letter-spacing: $button-1-price-medium-letter-spacing;
  line-height: $button-1-price-medium-line-height;
  font-weight: $button-1-price-medium-font-weight;
}
/** button-2-bold **/
$button-2-bold-font-family: 'Inter Ultra', 'InterUltra-Bold';
$button-2-bold-font-size: 10px;
$button-2-bold-letter-spacing: 0px;
$button-2-bold-line-height: 12px;
$button-2-bold-font-weight: 700;
$button-2-bold-color: black;

@mixin button-2-bold() {
  font-family: $button-2-bold-font-family;
  font-size: $button-2-bold-font-size;
  letter-spacing: $button-2-bold-letter-spacing;
  line-height: $button-2-bold-line-height;
  font-weight: $button-2-bold-font-weight;
}
/** button-2-price-medium **/
$button-2-price-medium-font-family: 'Inter Ultra', 'InterUltra-Medium';
$button-2-price-medium-font-size: 10px;
$button-2-price-medium-letter-spacing: 0px;
$button-2-price-medium-line-height: 12px;
$button-2-price-medium-font-weight: 500;

@mixin button-2-price-medium() {
  font-family: $button-2-price-medium-font-family;
  font-size: $button-2-price-medium-font-size;
  letter-spacing: $button-2-price-medium-letter-spacing;
  line-height: $button-2-price-medium-line-height;
  font-weight: $button-2-price-medium-font-weight;
}
/** h1-bold **/
$h1-bold-font-family: 'Inter Ultra', 'InterUltra-Bold';
$h1-bold-font-size: 96px;
$h1-bold-letter-spacing: 0px;
$h1-bold-line-height: 112px;
$h1-bold-font-weight: 700;
$h1-bold-color: black;

@mixin h1-bold() {
  font-family: $h1-bold-font-family;
  font-size: $h1-bold-font-size;
  letter-spacing: $h1-bold-letter-spacing;
  line-height: $h1-bold-line-height;
  font-weight: $h1-bold-font-weight;
  color: $h1-bold-color;
}
/** h1-mono-bold **/
$h1-mono-bold-font-family: 'Roboto Ultra Mono', 'RobotoUltraMono-Bold';
$h1-mono-bold-font-size: 96px;
$h1-mono-bold-letter-spacing: 0px;
$h1-mono-bold-line-height: 112px;
$h1-mono-bold-font-weight: 700;
$h1-mono-bold-color: black;

@mixin h1-mono-bold() {
  font-family: $h1-mono-bold-font-family;
  font-size: $h1-mono-bold-font-size;
  letter-spacing: $h1-mono-bold-letter-spacing;
  line-height: $h1-mono-bold-line-height;
  font-weight: $h1-mono-bold-font-weight;
  color: $h1-mono-bold-color;
}
/** h1-regular **/
$h1-regular-font-family: 'Inter Ultra', 'InterUltra-Regular';
$h1-regular-font-size: 96px;
$h1-regular-letter-spacing: 0px;
$h1-regular-line-height: 112px;
$h1-regular-font-weight: 400;
$h1-regular-color: black;

@mixin h1-regular() {
  font-family: $h1-regular-font-family;
  font-size: $h1-regular-font-size;
  letter-spacing: $h1-regular-letter-spacing;
  line-height: $h1-regular-line-height;
  font-weight: $h1-regular-font-weight;
  color: $h1-regular-color;
}
/** h2-bold **/
$h2-bold-font-family: 'Inter Ultra', 'InterUltra-Bold';
$h2-bold-font-size: 60px;
$h2-bold-letter-spacing: 0px;
$h2-bold-line-height: 76px;
$h2-bold-font-weight: 700;
$h2-bold-color: black;

@mixin h2-bold() {
  font-family: $h2-bold-font-family;
  font-size: $h2-bold-font-size;
  letter-spacing: $h2-bold-letter-spacing;
  line-height: $h2-bold-line-height;
  font-weight: $h2-bold-font-weight;
  color: $h2-bold-color;
}
/** h2-mono-bold **/
$h2-mono-bold-font-family: 'Roboto Ultra Mono', 'RobotoUltraMono-Bold';
$h2-mono-bold-font-size: 60px;
$h2-mono-bold-letter-spacing: 0px;
$h2-mono-bold-line-height: 76px;
$h2-mono-bold-font-weight: 700;
$h2-mono-bold-color: black;

@mixin h2-mono-bold() {
  font-family: $h2-mono-bold-font-family;
  font-size: $h2-mono-bold-font-size;
  letter-spacing: $h2-mono-bold-letter-spacing;
  line-height: $h2-mono-bold-line-height;
  font-weight: $h2-mono-bold-font-weight;
  color: $h2-mono-bold-color;
}
/** h2-regular **/
$h2-regular-font-family: 'Inter Ultra', 'InterUltra-Regular';
$h2-regular-font-size: 60px;
$h2-regular-letter-spacing: 0px;
$h2-regular-line-height: 76px;
$h2-regular-font-weight: 400;
$h2-regular-color: black;

@mixin h2-regular() {
  font-family: $h2-regular-font-family;
  font-size: $h2-regular-font-size;
  letter-spacing: $h2-regular-letter-spacing;
  line-height: $h2-regular-line-height;
  font-weight: $h2-regular-font-weight;
  color: $h2-regular-color;
}
/** h3-bold **/
$h3-bold-font-family: 'Inter Ultra', 'InterUltra-Bold';
$h3-bold-font-size: 48px;
$h3-bold-letter-spacing: 0px;
$h3-bold-line-height: 60px;
$h3-bold-font-weight: 700;
$h3-bold-color: black;

@mixin h3-bold() {
  font-family: $h3-bold-font-family;
  font-size: $h3-bold-font-size;
  letter-spacing: $h3-bold-letter-spacing;
  line-height: $h3-bold-line-height;
  font-weight: $h3-bold-font-weight;
  color: $h3-bold-color;
}
/** h3-mono-bold **/
$h3-mono-bold-font-family: 'Roboto Ultra Mono', 'RobotoUltraMono-Bold';
$h3-mono-bold-font-size: 48px;
$h3-mono-bold-letter-spacing: 0px;
$h3-mono-bold-line-height: 60px;
$h3-mono-bold-font-weight: 700;
$h3-mono-bold-color: black;

@mixin h3-mono-bold() {
  font-family: $h3-mono-bold-font-family;
  font-size: $h3-mono-bold-font-size;
  letter-spacing: $h3-mono-bold-letter-spacing;
  line-height: $h3-mono-bold-line-height;
  font-weight: $h3-mono-bold-font-weight;
  color: $h3-mono-bold-color;
}
/** h3-regular **/
$h3-regular-font-family: 'Inter Ultra', 'InterUltra-Regular';
$h3-regular-font-size: 48px;
$h3-regular-letter-spacing: 0px;
$h3-regular-line-height: 60px;
$h3-regular-font-weight: 400;
$h3-regular-color: black;

@mixin h3-regular() {
  font-family: $h3-regular-font-family;
  font-size: $h3-regular-font-size;
  letter-spacing: $h3-regular-letter-spacing;
  line-height: $h3-regular-line-height;
  font-weight: $h3-regular-font-weight;
  color: $h3-regular-color;
}
/** h4-bold **/
$h4-bold-font-family: 'Inter Ultra', 'InterUltra-Bold';
$h4-bold-font-size: 36px;
$h4-bold-letter-spacing: 0px;
$h4-bold-line-height: 44px;
$h4-bold-font-weight: 700;
$h4-bold-color: black;

@mixin h4-bold() {
  font-family: $h4-bold-font-family;
  font-size: $h4-bold-font-size;
  letter-spacing: $h4-bold-letter-spacing;
  line-height: $h4-bold-line-height;
  font-weight: $h4-bold-font-weight;
  color: $h4-bold-color;
}
/** h4-mono-bold **/
$h4-mono-bold-font-family: 'Roboto Ultra Mono', 'RobotoUltraMono-Bold';
$h4-mono-bold-font-size: 36px;
$h4-mono-bold-letter-spacing: 0px;
$h4-mono-bold-line-height: 44px;
$h4-mono-bold-font-weight: 700;
$h4-mono-bold-text-transform: capitalize;
$h4-mono-bold-color: black;

@mixin h4-mono-bold() {
  font-family: $h4-mono-bold-font-family;
  font-size: $h4-mono-bold-font-size;
  letter-spacing: $h4-mono-bold-letter-spacing;
  line-height: $h4-mono-bold-line-height;
  font-weight: $h4-mono-bold-font-weight;
  text-transform: $h4-mono-bold-text-transform;
  color: $h4-mono-bold-color;
}
/** h4-regular **/
$h4-regular-font-family: 'Inter Ultra', 'InterUltra-Regular';
$h4-regular-font-size: 36px;
$h4-regular-letter-spacing: 0px;
$h4-regular-line-height: 44px;
$h4-regular-font-weight: 400;
$h4-regular-color: black;

@mixin h4-regular() {
  font-family: $h4-regular-font-family;
  font-size: $h4-regular-font-size;
  letter-spacing: $h4-regular-letter-spacing;
  line-height: $h4-regular-line-height;
  font-weight: $h4-regular-font-weight;
  color: $h4-regular-color;
}
/** h5-bold **/
$h5-bold-font-family: 'Inter Ultra', 'InterUltra-Bold';
$h5-bold-font-size: 28px;
$h5-bold-letter-spacing: 0px;
$h5-bold-line-height: 36px;
$h5-bold-font-weight: 700;
$h5-bold-color: black;

@mixin h5-bold() {
  font-family: $h5-bold-font-family;
  font-size: $h5-bold-font-size;
  letter-spacing: $h5-bold-letter-spacing;
  line-height: $h5-bold-line-height;
  font-weight: $h5-bold-font-weight;
  color: $h5-bold-color;
}
/** h5-mono-bold **/
$h5-mono-bold-font-family: 'Roboto Mono', 'RobotoMono-Bold';
$h5-mono-bold-font-size: 28px;
$h5-mono-bold-letter-spacing: 0px;
$h5-mono-bold-line-height: 36px;
$h5-mono-bold-font-weight: 700;
$h5-mono-bold-color: black;

@mixin h5-mono-bold() {
  font-family: $h5-mono-bold-font-family;
  font-size: $h5-mono-bold-font-size;
  letter-spacing: $h5-mono-bold-letter-spacing;
  line-height: $h5-mono-bold-line-height;
  font-weight: $h5-mono-bold-font-weight;
  color: $h5-mono-bold-color;
}
/** h5-mono-bold **/
$h5-mono-bold-font-family: 'Roboto Ultra Mono', 'RobotoUltraMono-Bold';
$h5-mono-bold-font-size: 28px;
$h5-mono-bold-letter-spacing: 0px;
$h5-mono-bold-line-height: 36px;
$h5-mono-bold-font-weight: 700;
$h5-mono-bold-color: black;

@mixin h5-mono-bold() {
  font-family: $h5-mono-bold-font-family;
  font-size: $h5-mono-bold-font-size;
  letter-spacing: $h5-mono-bold-letter-spacing;
  line-height: $h5-mono-bold-line-height;
  font-weight: $h5-mono-bold-font-weight;
  color: $h5-mono-bold-color;
}
/** h5-regular **/
$h5-regular-font-family: 'Inter Ultra', 'InterUltra-Regular';
$h5-regular-font-size: 28px;
$h5-regular-letter-spacing: 0px;
$h5-regular-line-height: 36px;
$h5-regular-font-weight: 400;
$h5-regular-color: black;

@mixin h5-regular() {
  font-family: $h5-regular-font-family;
  font-size: $h5-regular-font-size;
  letter-spacing: $h5-regular-letter-spacing;
  line-height: $h5-regular-line-height;
  font-weight: $h5-regular-font-weight;
  color: $h5-regular-color;
}
/** h6-bold **/
$h6-bold-font-family: 'Inter Ultra', 'InterUltra-Bold';
$h6-bold-font-size: 24px;
$h6-bold-letter-spacing: 0px;
$h6-bold-line-height: 32px;
$h6-bold-font-weight: 700;
$h6-bold-color: black;

@mixin h6-bold() {
  font-family: $h6-bold-font-family;
  font-size: $h6-bold-font-size;
  letter-spacing: $h6-bold-letter-spacing;
  line-height: $h6-bold-line-height;
  font-weight: $h6-bold-font-weight;
  color: $h6-bold-color;
}
/** h6-mono-bold **/
$h6-mono-bold-font-family: 'Roboto Ultra Mono', 'RobotoUltraMono-Bold';
$h6-mono-bold-font-size: 24px;
$h6-mono-bold-letter-spacing: 0px;
$h6-mono-bold-line-height: 32px;
$h6-mono-bold-font-weight: 700;
$h6-mono-bold-color: black;

@mixin h6-mono-bold() {
  font-family: $h6-mono-bold-font-family;
  font-size: $h6-mono-bold-font-size;
  letter-spacing: $h6-mono-bold-letter-spacing;
  line-height: $h6-mono-bold-line-height;
  font-weight: $h6-mono-bold-font-weight;
  color: $h6-mono-bold-color;
}
/** h6-regular **/
$h6-regular-font-family: 'Inter Ultra', 'InterUltra-Regular';
$h6-regular-font-size: 24px;
$h6-regular-letter-spacing: 0px;
$h6-regular-line-height: 32px;
$h6-regular-font-weight: 400;
$h6-regular-color: black;

@mixin h6-regular() {
  font-family: $h6-regular-font-family;
  font-size: $h6-regular-font-size;
  letter-spacing: $h6-regular-letter-spacing;
  line-height: $h6-regular-line-height;
  font-weight: $h6-regular-font-weight;
  color: $h6-regular-color;
}
/** medium **/
$medium-font-family: 'Inter Ultra', 'InterUltra-Medium';
$medium-font-size: 10px;
$medium-letter-spacing: 1.5px;
$medium-line-height: 16px;
$medium-font-weight: 500;
$medium-text-transform: uppercase;
$medium-color: black;

@mixin medium() {
  font-family: $medium-font-family;
  font-size: $medium-font-size;
  letter-spacing: $medium-letter-spacing;
  line-height: $medium-line-height;
  font-weight: $medium-font-weight;
  text-transform: $medium-text-transform;
  color: $medium-color;
}
/** mono-bold **/
$mono-bold-font-family: 'Roboto Ultra Mono', 'RobotoUltraMono-Bold';
$mono-bold-font-size: 11px;
$mono-bold-letter-spacing: 0px;
$mono-bold-line-height: 16px;
$mono-bold-font-weight: 700;
$mono-bold-color: black;

@mixin mono-bold() {
  font-family: $mono-bold-font-family;
  font-size: $mono-bold-font-size;
  letter-spacing: $mono-bold-letter-spacing;
  line-height: $mono-bold-line-height;
  font-weight: $mono-bold-font-weight;
  color: $mono-bold-color;
}
/** mono-regular **/
$mono-regular-font-family: 'Roboto Ultra Mono', 'RobotoUltraMono-Regular';
$mono-regular-font-size: 11px;
$mono-regular-letter-spacing: 0px;
$mono-regular-line-height: 16px;
$mono-regular-font-weight: 400;
$mono-regular-color: black;

@mixin mono-regular() {
  font-family: $mono-regular-font-family;
  font-size: $mono-regular-font-size;
  letter-spacing: $mono-regular-letter-spacing;
  line-height: $mono-regular-line-height;
  font-weight: $mono-regular-font-weight;
  color: $mono-regular-color;
}
/** regular **/
$regular-font-family: 'Inter Ultra', 'InterUltra-Regular';
$regular-font-size: 11px;
$regular-letter-spacing: 0px;
$regular-line-height: 16px;
$regular-font-weight: 400;
$regular-color: black;

@mixin regular() {
  font-family: $regular-font-family;
  font-size: $regular-font-size;
  letter-spacing: $regular-letter-spacing;
  line-height: $regular-line-height;
  font-weight: $regular-font-weight;
  color: $regular-color;
}
/** s1-bold **/
$s1-bold-font-family: 'Inter Ultra', 'InterUltra-Bold';
$s1-bold-font-size: 16px;
$s1-bold-letter-spacing: 0px;
$s1-bold-line-height: 24px;
$s1-bold-font-weight: 700;
$s1-bold-color: black;

@mixin s1-bold() {
  font-family: $s1-bold-font-family;
  font-size: $s1-bold-font-size;
  letter-spacing: $s1-bold-letter-spacing;
  line-height: $s1-bold-line-height;
  font-weight: $s1-bold-font-weight;
  color: $s1-bold-color;
}
/** s1-mono-bold **/
$s1-mono-bold-font-family: 'Roboto Ultra Mono', 'RobotoUltraMono-Bold';
$s1-mono-bold-font-size: 16px;
$s1-mono-bold-letter-spacing: 0px;
$s1-mono-bold-line-height: 24px;
$s1-mono-bold-font-weight: 700;
$s1-bold-color: black;

@mixin s1-mono-bold {
  font-family: $s1-mono-bold-font-family;
  font-size: $s1-mono-bold-font-size;
  letter-spacing: $s1-mono-bold-letter-spacing;
  line-height: $s1-mono-bold-line-height;
  font-weight: $s1-mono-bold-font-weight;
  color: $s1-bold-color;
}
/** s2-bold **/
$s2-bold-font-family: 'Inter Ultra', 'InterUltra-Bold';
$s2-bold-font-size: 14px;
$s2-bold-letter-spacing: 0px;
$s2-bold-line-height: 20px;
$s2-bold-font-weight: 700;
$s2-bold-color: black;

@mixin s2-bold() {
  font-family: $s2-bold-font-family;
  font-size: $s2-bold-font-size;
  letter-spacing: $s2-bold-letter-spacing;
  line-height: $s2-bold-line-height;
  font-weight: $s2-bold-font-weight;
  color: $s2-bold-color;
}
/** s2-mono-bold **/
$s2-mono-bold-font-family: 'Roboto Ultra Mono', 'RobotoUltraMono-Bold';
$s2-mono-bold-font-size: 14px;
$s2-mono-bold-letter-spacing: 0px;
$s2-mono-bold-line-height: 20px;
$s2-mono-bold-font-weight: 700;
$s2-mono-bold-color: black;

@mixin s2-mono-bold() {
  font-family: $s2-mono-bold-font-family;
  font-size: $s2-mono-bold-font-size;
  letter-spacing: $s2-mono-bold-letter-spacing;
  line-height: $s2-mono-bold-line-height;
  font-weight: $s2-mono-bold-font-weight;
  color: $s2-mono-bold-color;
}

/*
no color defined for those: 
$b1-bold-color;
$b2-bold-color;
*/

/* to avoid extra code to write to overide 'black', override before 'compilation' */

$override-color: white;

$h1-regular-color: $override-color;
$h1-bold-color: $override-color;
$h1-mono-bold-color: $override-color;

$h2-regular-color: $override-color;
$h2-bold-color: $override-color;
$h2-mono-bold-color: $override-color;

$h3-regular-color: $override-color;
$h3-bold-color: $override-color;
$h3-mono-bold-color: $override-color;

$h4-regular-color: $override-color;
$h4-bold-color: $override-color;
$h4-mono-bold-color: $override-color;

$h5-regular-color: $override-color;
$h5-bold-color: $override-color;
$h5-mono-bold-color: $override-color;

$h6-regular-color: $override-color;
$h6-bold-color: $override-color;
$h6-mono-bold-color: $override-color;

$s1-bold-color: $override-color;
$s2-bold-color: $override-color;
$medium-color: $override-color;
