.modal {
  z-index: 1000 !important;
  &-backdrop {
    z-index: 1000 !important;
    // custom effect, can be used by define backdropClass option

    &.blur {
      filter: blur(15px);
    }
  }

  &-header {
    padding: 1rem;
    border-bottom: 1px solid #dee2e6;
  }

  &-body {
    padding: 1rem;
    overflow: hidden;
  }

  &-footer {
    border-top: 1px solid #dee2e6;
    padding: 0.75rem;
  }

  &-footer > * {
    margin: 0;
  }

  &-separate {
    margin-left: -20px;
    margin-right: -20px;
  }
}

// custom types, can be used by define windowClass option

.dark-modal {
  .modal {
    &-content {
      background-color: $grey-dark-dk;
      border: 1px solid $white-alpha-12;
      box-shadow: 0 remy(11) remy(15) $black-alpha-2, 0 remy(9) remy(46) $black-alpha-12,
        0 remy(24) remy(38) $black-alpha-12;
    }

    &-header {
      border-bottom-color: $white-alpha-12;
    }

    &-body {
      padding: remy(13) remy(21) remy(16);
    }

    &-header {
      padding: remy(18) remy(21);
      background-color: $grey-dark;
    }

    &-footer {
      border: none;
      background-color: $grey-dark;

      & > *:not(:last-child),
      & > *:not(:first-child) {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  .btn-close {
    margin-right: 0.7rem;
    transition: 0.3s opacity ease;
  }
}

.inform-modal {
  .modal {
    &-content {
      border: 1px solid $grey-light;
      background: $grey-dark-2-standard-bg;
      box-shadow: 0 remy(11) remy(15) $black-alpha-2, 0 remy(9) remy(46) $black-alpha-12,
        0 remy(24) remy(38) $black-alpha-12;
    }

    &-header {
      border-bottom: none;
      padding: remy(26) remy(20) remy(16);
    }

    &-body {
      padding: 0 remy(20);
    }

    &-footer {
      padding: remy(20);
      border-color: $grey-light;
    }
  }
}

.medium-dark-modal {
  .modal {
    &-content {
      background: $grey-dark-2-standard-bg;
      border: 1px solid $white-alpha-1;
      box-shadow: 0 remy(11) remy(15) $black-alpha-2, 0 remy(9) remy(46) $black-alpha-12,
        0 remy(24) remy(38) $black-alpha-12;
    }

    &-body {
      overflow: visible;
    }

    &-header {
      border-color: $white-alpha-1;
    }

    &-footer {
      border-color: $white-alpha-1;
    }

    &-footer,
    &-header,
    &-body {
      padding: remy(20);
    }

    &-separate {
      border-color: $white-alpha-1;
    }
  }
}

@media (min-width: 576px) {
  .dark-modal .modal-dialog {
    max-width: 540px;
  }

  .inform-modal,
  .medium-dark-modal {
    .modal-dialog {
      max-width: 660px;
    }
  }
  .medium-dark-modal.dlc-modal {
    .modal-dialog {
      max-width: 1095px;
    }
  }

  .medium-dark-modal.session-modal {
    .modal-dialog {
      max-width: 480px;
    }
  }

  .medium-dark-modal.tags-modal {
    .modal-dialog {
      max-width: 380px;
    }
  }

  .medium-dark-modal.age-rating-modal {
    .modal-dialog {
      max-width: 400px;
    }
  }
}

.modal-dialog {
  &.modal-xl {
    border-radius: 6px;
    width: 720px;

    @media (min-width: 900px) {
      max-width: 882px;
      width: 882px;
    }

    @media (min-width: 1200px) {
      max-width: 1132px;
      width: 1132px;
    }
  }
}

.backdrop-dark {
  background-color: rgba($grey-medium-dark, 0.9);
  backdrop-filter: blur(32px);
  opacity: 1 !important;
}

.modal.modal-static {
  transition-property: none !important;
  transform: none !important;
  animation: none !important;
}
