.price {
  display: inline-block;
  height: 32px;
  min-width: 90px;
  cursor: pointer;
  font-weight: bold;
  font-size: remy(12);
  border: 0;
  -webkit-appearance: none;

  &--purple {
    color: $white;
    background-color: $ultra-purple;
    &:not([disabled]):hover,
    &:not([disabled]):focus {
      background-color: $ultra-purple-hover;
    }
  }

  &--disabled {
    color: $white-alpha-5;
    background-color: $ultra-purple-disabled;
    cursor: default;
  }

  &--transparent {
    color: $ultra-purple-light;
    background-color: $ultra-purple-light-transparent;
    cursor: default;
  }

  &--empty {
    background-color: $white-alpha-1;
  }

  &__text-active {
    display: none;
  }

  &__currency:not([disabled]):hover,
  &__currency:not([disabled]):focus {
    ultra-uniq-price,
    .price__text {
      display: none;
    }
    .price__text-active {
      display: block;
    }
  }
}

.game__price {
  .price {
    height: 40px;
  }
}
